import React from "react";
import CategoryItem from "./CategoryItem";
import { data } from "../data/CategoryData";
import { Container } from "../styledcomponents/home/Category";

const Category = () => {
  // const [category, setCategory] = useState([]);
  // useEffect(() => {
  //   console.log(category);
  //   getRequest().then((response) => {
  //     setCategory(response.data);
  //   });
  // });
  return (
    <Container>
      {data.map((output) => (
        <CategoryItem item={output} key={output.id} />
      ))}
    </Container>
  );
};

export default Category;
