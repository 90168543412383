import React from "react";
import classes from "./DonateCard.module.css";
const DonateCard = () => {
  return (
    <div className={classes["card"]}>
      <div className={classes["username"]}>
        <h6>Anurag</h6>
      </div>
      <div className={classes["userinfo"]}>
        <div className={classes.div}>
          <b> USER ID :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b>NAME :</b> <span>Anurag kumar gupta</span>{" "}
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
              aliquam quibusdam iure sint quia tempore, adipisci corrupti id
              assumenda eum cumque quis. Voluptatibus veniam non, sapiente rerum
              ab laudantium nisi.
            </span>
          </div>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ITEM DETAIL: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
              aliquam quibusdam iure sint quia tempore, adipisci corrupti id
              assumenda eum cumque quis. Voluptatibus veniam non, sapiente rerum
              ab laudantium nisi.
            </span>
          </div>
        </div>
        <div className={classes.div}>
          <b>Volunteer Name :</b> <span>Anurag@gmail.com</span>
        </div>
        <div className={classes.div}>
          <b> MOBILE NUMBER:</b> <span>+91 9696058889</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>Volunteer description: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
              aliquam quibusdam iure sint quia tempore, adipisci corrupti id
              assumenda eum cumque quis. Voluptatibus veniam non, sapiente rerum
              ab laudantium nisi.
            </span>
          </div>
        </div>
        <div className={classes.div}>
          <b> request date :</b> <span>2023-05-12</span>
        </div>
        <div className={classes.div}>
          <b>collected date :</b> <span>2023-05-23</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>action: </b>
            <span>Assigned to volunteer</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateCard;
