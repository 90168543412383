import React from "react";
import DataTable from "react-data-table-component";

const Table = ({ columns, data }) => {
  return (
    <div className="mt-3">
      <DataTable
        columns={columns}
        data={data}
        fixedHeader
        highlightOnHover
        striped
        pagination
        customStyles={{
          headCells: {
            style: {
              justifyContent: "center",
              fontSize: "16px",
              backgroundColor: "#00838F",
              color: "white",
              fontWeight: 900,
            },
          },
          rows: {
            style: {
              justifyContent: "center",
              // color: "black",
              backgroundColor: " #b2dfdb",
              fontSize: "14px",
              fontWeight: "bolder",
            },
            stripedStyle: {
              justifyContent: "center",
              fontSize: "14px",
              // color: "black",
              backgroundColor: "#e0f2f1",
              fontWeight: "bolder",
            },
          },
        }}
      />
    </div>
  );
};

export default Table;
