import React from "react";
import { Heading } from "../styledcomponents/home/CustomHeading";
const CustomHeading = (props) => {
  return (
    <Heading>
      <h1>{props.title.toUpperCase()}</h1>;
    </Heading>
  );
};

export default CustomHeading;
