import React from "react";
import classes from "../categoryList/CategoryListCard.module.css";
const SubCategoryListCard = ({ img, title }) => {
  return (
    <div className={classes["sub-category-card"]}>
      <img src={img} alt="" className={classes.img} />
      <div className={classes.wrapper}>
        <h2 className={classes.title}>{title}</h2>
        <div className={classes["btn-wrapper"]}>
          <button className={classes.btn}>PRODUCT LIST</button>
        </div>
        <div className={classes["btn-wrapper"]}>
          <button className={classes.btn}>ACTIVE</button>
          <button className={classes.btn}>EDIT</button>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryListCard;
