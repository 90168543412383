import { FullName } from "../utils/Messages";
import { ConfirmPassword, Value, Password, PhoneNumber } from "./Common";

export const LoginValidations = ({ userName, password }) => {
  if (Value(userName, FullName) || Password(password)) {
    return false;
  } else {
    return true;
  }
};

export const RegisterValidations = ({
  fullName,
  phoneNumber,
  password,
  confirmpassword,
}) => {
  if (
    Value(fullName, FullName) ||
    Password(password) ||
    PhoneNumber(phoneNumber) ||
    ConfirmPassword(password, confirmpassword)
  ) {
    return false;
  } else {
    return true;
  }
};
