import { SupportedFile } from "../utils/HelperText";
import { ErrorMsg } from "../utils/Messages";

//validation for username
export const Value = (data, msg) => {
  if (data.trim() === "" || data === undefined || data.trim() === null) {
    ErrorMsg(msg);
    return true;
  } else return false;
};

//validation for Password
export const Password = (data) => {
  if (data.trim() === "" || data === undefined) {
    ErrorMsg("Please Enter Password");
    return true;
  } else if (data.length < 6) {
    ErrorMsg("Password Length should be more than 6");
    return true;
  } else {
    return false;
  }
};

// Validation for Confirming password
export const ConfirmPassword = (data1, data2) => {
  if (data1 !== data2) {
    ErrorMsg("Password does not Match ! Enter Again");
    return true;
  } else return false;
};

//validation for Phone Number
export const PhoneNumber = (data) => {
  if (data.trim() === "" || data === undefined) {
    ErrorMsg("Please Enter Phone Number");
    return true;
  } else if (data.length < 10) {
    ErrorMsg("Phone Number Must be of 10 digit");
    return true;
  } else return false;
};

//Validation for File Types
export const File = (data, msg) => {
  if (data === undefined || data === "") {
    ErrorMsg(msg[0]);
  }
  if (!SupportedFile.includes(data.type)) {
    ErrorMsg(msg[1]);
    return true;
  } else {
    return false;
  }
};
