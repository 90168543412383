import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/VolunteerList.module.css";
import SizeCard from "../../../ui/sizeCard/SizeCard";
import add from "../../../assests/images/add.png";
const SizeList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Size list" />
      <div className={classes.container}>
        <div className={classes.volunteer}>
          <div className={classes["volunteer-container"]}>
            <SizeCard size="XS" color="#EF9A9A" />
            <SizeCard size="S" color="#EF9A9A" />
            <SizeCard size="M" color="#EF9A9A" />
            <SizeCard size="L" color="#EF9A9A" />
            <SizeCard size="XL" color="#EF9A9A" />
            <SizeCard size="XX" color="#EF9A9A" />
            <SizeCard size="XXXL" color="#EF9A9A" />
            <img src={add} alt="" className={classes.add} />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default SizeList;
