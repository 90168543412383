import img1 from "../assests/images/Brands/Aurelia.jpg";
import img2 from "../assests/images/Brands/Indian Terrain.jpg";
import img3 from "../assests/images/Brands/Levis.jpg";
import img4 from "../assests/images/Brands/Lp.png";
import img5 from "../assests/images/Brands/Raymaond.png";
import img6 from "../assests/images/Brands/Zara_logo.png";
import img7 from "../assests/images/Brands/allensolly.webp";
import img8 from "../assests/images/Brands/biba.jpg";
import img9 from "../assests/images/Brands/calvin-klein.webp";
import img10 from "../assests/images/Brands/pepejeans.jpg";
import img11 from "../assests/images/Brands/wrangler.webp";

export const data = [
  {
    id: 1,
    image: img1,
  },
  {
    id: 2,
    image: img2,
  },
  {
    id: 3,
    image: img3,
  },
  {
    id: 4,
    image: img4,
  },
  {
    id: 5,
    image: img5,
  },
  {
    id: 6,
    image: img6,
  },
  {
    id: 7,
    image: img7,
  },
  {
    id: 8,
    image: img8,
  },
  {
    id: 9,
    image: img9,
  },
  {
    id: 10,
    image: img10,
  },
  {
    id: 11,
    image: img11,
  },

  {
    id: 12,
    image: img2,
  },
  {
    id: 13,
    image: img3,
  },
  {
    id: 14,
    image: img4,
  },
  {
    id: 15,
    image: img5,
  },
  {
    id: 16,
    image: img6,
  },
  {
    id: 17,
    image: img7,
  },
  {
    id: 18,
    image: img8,
  },
  {
    id: 19,
    image: img9,
  },
  {
    id: 20,
    image: img10,
  },
  {
    id: 21,
    image: img1,
  },
  {
    id: 22,
    image: img11,
  },
];
