import img1 from "../assests/images/Category/mens.jpg";
import img2 from "../assests/images/Category/women.jpg";
import img3 from "../assests/images/Category/children.jpg";
import { getRequest } from "../network/Services";
import { getAllCategory } from "../network/APIUrls";
import { headerWithToken } from "../network/Headers";
export const data = [
  {
    id: 1,
    image: img1,
    title: "MEN STYLE",
    btnText: "SHOP NOW",
  },
  {
    id: 2,
    image: img2,
    title: "WOMEN STYLE",
    btnText: "SHOP NOW",
  },
  {
    id: 3,
    image: img3,
    title: "CHILDREN STYLE",
    btnText: "SHOP NOW",
  },
];
