import React from "react";
import classes from "../volunteerCard/VolunteerListCard.module.css";
import img from "../../assests/images/Shirts/Shirt1.jpg";

const VolRequestListCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.username}>
        <h6>john dev</h6>
      </div>
      <img src={img} alt="" className={classes.image} />
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> USER ID :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b>ROLE :</b> <span>Anurag kumar gupta</span>{" "}
        </div>
        <div className={classes.div}>
          <b>EMAIL :</b> <span>Anurag@gmail.com</span>
        </div>
        <div className={classes.div}>
          <b> MOBILE NUMBER:</b> <span>+91 9696058889</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Dignissimos sint perferendis, quisquam maiores sapiente rem quam
              dolor. Asperiores, explicabo sint? Ratione, voluptate? Mollitia
              eum deserunt ea velit corrupti. Libero, tempore.
            </span>
          </div>
          <img src={img} alt="" className={classes["address-proof"]} />
        </div>
        <div className={classes.aadhaar}>
          <div className={classes["aadhaar-no"]}>
            <b>AADHAAR : </b>
            <span>987654321012</span>
          </div>
          <div className={classes["aadhaar-pics"]}>
            <img src={img} alt="" className={classes["front-side"]} />
            <img src={img} alt="" className={classes["back-side"]} />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.btn} disabled>
          DEACTIVATE
        </button>
        <button className={classes.btn}>ACTIVATE</button>
      </div>
    </div>
  );
};

export default VolRequestListCard;
