import React from "react";
import classes from "../../../styledcomponents/admin/users/MerchantList.module.css";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import MerchantRequestListCard from "../../../ui/merchantRequestCard/MerchantRequestListCard";
const MerchantRequestList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="merchant list" />
      <div className={classes.container}>
        <div className={classes.merchant}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["merchant-container"]}>
            <MerchantRequestListCard />
            <MerchantRequestListCard />
            <MerchantRequestListCard />
            <MerchantRequestListCard />
            <MerchantRequestListCard />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default MerchantRequestList;
