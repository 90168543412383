import img from "../assests/images/Tshirts/Tshirt2-removebg-preview.png";

export const data = [
  {
    id: 1,
    image: img,
    title: "kurta sets",
  },
  {
    id: 2,
    image: img,
    title: "Handbags",
  },
  {
    id: 3,
    image: img,
    title: "kurtas",
  },
  {
    id: 4,
    image: img,
    title: "T-Shirts",
  },
  {
    id: 5,
    image: img,
    title: "Sarees",
  },
  {
    id: 6,
    image: img,
    title: "Shirts",
  },
  {
    id: 7,
    image: img,
    title: "Jewellery",
  },
  {
    id: 8,
    image: img,
    title: "Teen Wear",
  },
  {
    id: 9,
    image: img,
    title: "Beauty",
  },
  {
    id: 10,
    image: img,
    title: "Plus Size Styles",
  },
  {
    id: 11,
    image: img,
    title: "Jeans",
  },
  {
    id: 12,
    image: img,
    title: "Sport Shoes",
  },
  {
    id: 13,
    image: img,
    title: "Trousers",
  },
  {
    id: 14,
    image: img,
    title: "Causual Shoes",
  },
  // {
  //   id: 15,
  //   image: img,
  //   title: "Track Pants",
  // },
  // {
  //   id: 16,
  //   image: img,
  //   title: "Inner Wear",
  // },
  // {
  //   id: 17,
  //   image: img,
  //   title: "kurta sets",
  // },
  // {
  //   id: 18,
  //   image: img,
  //   title: "Handbags",
  // },
  // {
  //   id: 19,
  //   image: img,
  //   title: "kurtas",
  // },
  // {
  //   id: 20,
  //   image: img,
  //   title: "T-Shirts",
  // },
  // {
  //   id: 21,
  //   image: img,
  //   title: "Sarees",
  // },
  // {
  //   id: 22,
  //   image: img,
  //   title: "Shirts",
  // },
  // {
  //   id: 23,
  //   image: img,
  //   title: "Jewellery",
  // },
  // {
  //   id: 24,
  //   image: img,
  //   title: "Teen Wear",
  // },
  // {
  //   id: 25,
  //   image: img,
  //   title: "Beauty",
  // },
  // {
  //   id: 26,
  //   image: img,
  //   title: "Plus Size Styles",
  // },
  // {
  //   id: 27,
  //   image: img,
  //   title: "Jeans",
  // },
  // {
  //   id: 28,
  //   image: img,
  //   title: "Sport Shoes",
  // },
  // {
  //   id: 29,
  //   image: img,
  //   title: "Trousers",
  // },
  // {
  //   id: 30,
  //   image: img,
  //   title: "Causual Shoes",
  // },
  // {
  //   id: 31,
  //   image: img,
  //   title: "Track Pants",
  // },
  // {
  //   id: 32,
  //   image: img,
  //   title: "Inner Wear",
  // },
];
