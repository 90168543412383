import React from "react";
import {
  Container,
  Desc,
  Filter,
  FilterColor,
  FilterContainer,
  FilterSize,
  FilterTitle,
  Image,
  ImageContainer,
  InfoContainer,
  Price,
  Title,
  Wrapper,
  FilterSizeOption,
  AmountContainer,
  Amount,
  Button,
  AddContainer,
} from "../../styledcomponents/product/Product";

import { Add, Remove } from "@mui/icons-material";
import Base from "./Base";

const Product = () => {
  return (
    <Container>
      <Base>
        <Wrapper>
          <ImageContainer>
            <Image src="https://i.ibb.co/S6qMxwr/jean.jpg" />
          </ImageContainer>
          <InfoContainer>
            <Title>Denim Jumpsuit</Title>
            <Desc>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              tristique, neque ac vehicula egestas, justo urna euismod nunc, vel
              faucibus odio libero quis neque. Nullam risus massa, mollis sit
              amet enim porta, suscipit congue urna. Pellentesque dictum ligula
              lacus, ut imperdiet augue congue nec. Sed eros erat, porttitor sit
              amet congue id, commodo sed nulla. Cras lacinia dolor at accumsan
              egestas. Maecenas vitae pretium purus, nec sagittis urna. Sed
              lacinia in dolor quis porttitor. Cras eget felis leo. Maecenas
              quis odio mi. Quisque nec tincidunt sem. Ut eu accumsan libero.
            </Desc>
            <Price>2000₹</Price>
            <FilterContainer>
              <Filter>
                <FilterTitle>Color</FilterTitle>
                <FilterColor color="black" />
                <FilterColor color="darkblue" />
                <FilterColor color="gray" />
              </Filter>
              <Filter>
                <FilterTitle>Size</FilterTitle>
                <FilterSize>
                  <FilterSizeOption>XS</FilterSizeOption>
                  <FilterSizeOption>S</FilterSizeOption>
                  <FilterSizeOption>M</FilterSizeOption>
                  <FilterSizeOption>L</FilterSizeOption>
                  <FilterSizeOption>XL</FilterSizeOption>
                  <FilterSizeOption>XXL</FilterSizeOption>
                </FilterSize>
              </Filter>
            </FilterContainer>
            <AddContainer>
              <AmountContainer>
                <Remove />
                <Amount>1</Amount>
                <Add />
              </AmountContainer>
              <Button>ADD TO CART</Button>
            </AddContainer>
          </InfoContainer>
        </Wrapper>
      </Base>
    </Container>
  );
};

export default Product;
