import React from "react";
import classes from "./PackageTypeCard.module.css";
import { useNavigate } from "react-router-dom";
const PackageTypeCard = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.card}
      onClick={() => navigate("/admin/package/packagelist")}
    >
      <div className={classes["description"]}>
        <h3>Silver</h3>
      </div>
      <div className={classes["description"]}>
        <h5 className={classes.description}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam
          debitis quidem dolores qui consequuntur. Amet culpa molestias eaque
          quos beatae sunt, iusto, magni neque iure, deleniti mollitia
          asperiores quaerat? Mollitia.
        </h5>
      </div>
    </div>
  );
};

export default PackageTypeCard;
