import React from "react";
import AdminBase from "../../pages/admin/AdminBase";
import CustomSubHeading from "../CustomSubHeading";
import classes from "../../styledcomponents/admin/users/MerchantList.module.css";
import PackageTypeCard from "../../ui/packageTypeCard/PackageTypeCard";
import { motion as m } from "framer-motion";

const PackageType = () => {
  return (
    <m.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      <AdminBase>
        <CustomSubHeading title="Package Type" />
        <div className={classes.container}>
          <div className={classes.merchant}>
            <div className={classes["merchant-container"]}>
              <PackageTypeCard />

              <PackageTypeCard />
              <PackageTypeCard />
              <PackageTypeCard />
            </div>
          </div>
        </div>
      </AdminBase>
    </m.div>
  );
};

export default PackageType;
