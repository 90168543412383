import "./App.css";
import { AnimatePresence } from "framer-motion";
import Cart from "./pages/users/Cart";
import Login from "./pages/Login";
import Product from "./pages/users/Product";
import ProductList from "./pages/users/ProductList";
import Register from "./pages/Register";
import Home from "./pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import ResetPassword from "./pages/ResetPassword";
import AdminHome from "./pages/admin/AdminHome";
import About from "./pages/admin/About";
import Contact from "./pages/admin/Contact";
import UserList from "./pages/admin/users/UserList";
import AddCategory from "./pages/admin/category/AddCategory";
import CategoryList from "./pages/admin/category/CategoryList";
import MLogin from "./pages/MLogin";
import OPT from "./pages/OPT";
import AddSubCategory from "./pages/admin/category/AddSubCategory";
import SubCategoryList from "./pages/admin/category/SubCategoryList";
import AddBanner from "./pages/admin/banner/AddBanner";
import BannerList from "./pages/admin/banner/BannerList";
import MerchantList from "./pages/admin/users/MerchantList";
import RoleList from "./pages/admin/users/RoleList";
import VolunteerList from "./pages/admin/users/VolunteerList";
import DeactivatedUserList from "./pages/admin/users/DeactivatedUserList";
import MerchantRequestList from "./pages/admin/users/MerchantRequestList";
import VolunteerRequestList from "./pages/admin/users/VolunteerRequestList";
import Donate from "./pages/admin/users/Donate";
import PackageList from "./pages/admin/package/PackageList";
import AddPackage from "./pages/admin/package/AddPackage";
import AssignCouponToVol from "./pages/admin/package/AssignCouponToVol";
import PackageBuyHistory from "./pages/admin/package/PackageBuyHistory";
import PackageRequest from "./pages/admin/package/PackageRequest";
import VolCouponHis from "./pages/admin/package/VolCouponHis";
import PackageType from "./components/admin/PackageType";
import Products from "./pages/admin/products/Products";
import SizeList from "./pages/admin/products/SizeList";
import LuckyDrawStartup from "./animations/LuckyDrawStartup";
import Index from "./pages/users/points&coupons/Index";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import AddProducts from "./pages/merchants/AddProducts";
import MerchantProducts from "./pages/merchants/MerchantProducts";
import Packages from "./pages/merchants/Packages";
import ScrollToTop from "./utils/ScrollToTop";
function App() {
  const location = useLocation();
  return (
    <>
      <ScrollToTop />
      <ToastContainer />
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/mlogin" element={<MLogin />} />
          <Route exact path="/otp" element={<OPT />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/productlist" element={<ProductList />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/pointsandcoupons" element={<Index />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          {/* admin's Routes */}
          <Route path="admin" element={<AdminHome />}></Route>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {/* User Module Routes */}
          <Route path="admin/users/userlist" element={<UserList />} />
          <Route path="admin/users/rolelist" element={<RoleList />} />
          <Route path="admin/users/merchantlist" element={<MerchantList />} />
          <Route path="admin/users/volunteerlist" element={<VolunteerList />} />
          <Route
            path="admin/users/deactivatedusers"
            element={<DeactivatedUserList />}
          />
          <Route
            path="admin/users/merchantrequest"
            element={<MerchantRequestList />}
          />
          <Route
            path="admin/users/volunteerrequest"
            element={<VolunteerRequestList />}
          />
          <Route path="admin/users/donate" element={<Donate />} />
          {/* End of User Module Route */}
          {/* Start of Package Module Routes */}
          <Route path="admin/package/packagetype" element={<PackageType />} />
          <Route path="admin/package/packagelist" element={<PackageList />} />
          <Route path="admin/package/addpackage" element={<AddPackage />} />
          <Route
            path="admin/package/packagerequest"
            element={<PackageRequest />}
          />
          <Route
            path="admin/package/packagebuyhistory"
            element={<PackageBuyHistory />}
          />
          <Route
            path="admin/package/assigncoupontovol"
            element={<AssignCouponToVol />}
          />
          <Route
            path="admin/package/volunteercouponhistory"
            element={<VolCouponHis />}
          />
          s{/* End of Package Module Routes */}
          {/* Start Category Module Routes */}
          <Route
            exact
            path="admin/category/addcategory"
            element={<AddCategory />}
          />
          <Route
            exact
            path="admin/category/categorylist"
            element={<CategoryList />}
          />
          <Route
            exact
            path="admin/category/subcategory"
            element={<AddSubCategory />}
          />
          <Route
            exact
            path="admin/category/subcategorylist"
            element={<SubCategoryList />}
          />
          {/* end of routes for category */}
          {/* Start for Routes for Banner module */}
          <Route exact path="admin/banner/addbanner" element={<AddBanner />} />
          <Route
            exact
            path="admin/banner/bannerlist"
            element={<BannerList />}
          />
          {/* End of Routes for Banner module */}
          {/* Start for Routes for Products */}
          <Route exact path="admin/products/products" element={<Products />} />
          <Route exact path="admin/products/sizelist" element={<SizeList />} />
          {/* end of product routes */}
          {/* start of lucky-draw */}
          <Route exact path="admin/luckydraw" element={<LuckyDrawStartup />} />
          {/* Merchants Routes */}
          <Route exact path="/addproduct" element={<AddProducts />} />
          <Route exact path="/packages" element={<Packages />} />
          <Route
            exact
            path="/merchantproducts/:type"
            element={<MerchantProducts />}
          />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
