import React from "react";
import CustomHeading from "../../../components/CustomHeading";
import classes from "../../../styledcomponents/admin/category/AddCategory.module.css";
import { InputAdornment, TextField } from "@mui/material";
import { Category, Description, InsertDriveFile } from "@mui/icons-material";
import AdminBase from "../AdminBase";
import { Form } from "../../../styledcomponents/login/Login";
import { AddCategoryValidation } from "../../../validations/Category";
import { postRequest } from "../../../network/Services";
import { category, uploadFile } from "../../../network/APIUrls";
import {
  headerWithToken,
  headerWithTokenwithMultiPart,
} from "../../../network/Headers";
import { StatusCheck } from "../../../network/ResponseStatus";
import GlobalSpinners from "../../../utils/spinners/GlobalSpinners";
import { useState } from "react";
import { ServerDown } from "../../../utils/Messages";
const AddCategory = () => {
  const [spinner, setSpinner] = useState(false);

  //function handling submit form to add category
  const handleAddCategory = (event) => {
    setSpinner(true);
    event.preventDefault();
    const categoryName = event.target.categoryName.value;
    const description = event.target.description.value;
    const categoryFile = event.target.categoryicon.files[0];

    const data = {
      categoryName,
      description,
      categoryFile,
    };

    if (AddCategoryValidation(data)) {
      //api call to upload file
      const formData = new FormData();
      formData.append("file", categoryFile);
      postRequest(uploadFile, formData, headerWithTokenwithMultiPart())
        .then((response) => {
          if (StatusCheck(response)) {
            const filename = response.data.fileName;
            //api call to submit complete category
            postRequest(
              category,
              { categoryName, description, filename },
              headerWithToken()
            )
              .then((response) => {
                if (StatusCheck(response)) {
                  setTimeout(() => {
                    setSpinner(false);
                  }, 1500);
                }
              })
              .catch((error) => {
                if (error.response === undefined) {
                  ServerDown();
                  setSpinner(false);
                } else {
                  StatusCheck(error.response);
                }
              });
          }
        })
        .catch((error) => {
          if (error.response === undefined) {
            ServerDown();
            setSpinner(false);
          } else {
            StatusCheck(error.response);
          }
        });
      //reset form on successful submit
      document.getElementById("addCategoryForm").reset();
    } else {
      setSpinner(false);
    }
  };
  return (
    <AdminBase>
      {spinner && <GlobalSpinners />}
      <CustomHeading title="add category" />
      <div className={classes.category}>
        <div className={classes["category-wrapper"]}>
          <Form onSubmit={handleAddCategory} id="addCategoryForm">
            <TextField
              placeholder="Category Name"
              name="categoryName"
              autoComplete="off"
              id="category-name"
              label="CATEGORY NAME"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Category />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder="Description"
              name="description"
              id="description"
              label="DESCRIPTION"
              variant="outlined"
              multiline
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              name="categoryicon"
              id="categoryicon"
              label="CATEGORY PHOTO"
              variant="outlined"
              type="file"
              inputProps={{ accept: "image/png,image/jpeg,image/jpg" }}
              InputProps={{
                className: classes.catname,

                startAdornment: (
                  <InputAdornment position="start">
                    <InsertDriveFile />
                  </InputAdornment>
                ),
              }}
            />
            <button className={classes.btn} type="submit">
              ADD
            </button>
          </Form>
        </div>
      </div>
    </AdminBase>
  );
};

export default AddCategory;
