import React from "react";
import classes from "../styledcomponents/home/SubCategory.module.css";
import { data } from "../data/SubCategoryData";
const SubCategory = () => {
  return (
    <div className={classes.container}>
      {data.map((item) => (
        <div className={classes.card} key={item.id}>
          <img src={item.image} alt="" className={classes.image} />
          <h5>{item.title}</h5>
        </div>
      ))}
    </div>
  );
};

export default SubCategory;
