import React from "react";
import CustomHeading from "../../components/CustomHeading";
import classes from "../../styledcomponents/admin/About.module.css";
import Card from "../../components/admin/Card";
import img1 from "../../assests/images/about/img1.jpg";
import img2 from "../../assests/images/about/img2.png";
import img3 from "../../assests/images/about/img3.jpg";
import img4 from "../../assests/images/about/img4.png";
import img5 from "../../assests/images/about/img5.jpg";
import img6 from "../../assests/images/about/img6.jpg";
import img7 from "../../assests/images/about/img7.jpg";
import {
  data1,
  data10,
  data11,
  data12,
  data13,
  data14,
  data2,
  data3,
  data4,
  data5,
  data6,
  data7,
  data8,
  data9,
} from "../../utils/AboutData";
import Base from "../users/Base";
const About = () => {
  return (
    <Base>
      <CustomHeading title="ABOUT US" />
      <div className={classes.about}>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card title="About Shhoffers App" image={img1} desc={data1} />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="हमारा ई बाजार ऐप के बारे में"
              image={img1}
              desc={data2}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="This app has Lucky coupon scheme. Benefits of Lucky Coupon"
              image={img2}
              desc={data3}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="इस app में Lucky coupon scheem हैं Lucky coupon के फायदे"
              image={img2}
              desc={data4}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="How to get joining points and its benefits"
              image={img3}
              desc={data5}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="Joining points कैसे मिलेंगे और इसके फायदे"
              image={img3}
              desc={data6}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="How to get Merchant points and Gift points and its benefits"
              image={img4}
              desc={data7}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="Merchant points और Gift points कैसे मिलेंगे और इसके फायदे"
              image={img4}
              desc={data8}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="Condition of return and replacement of goods"
              image={img5}
              desc={data9}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="सामान वापसी और बदलने की शर्त"
              image={img5}
              desc={data10}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="How to register merchant in this app"
              image={img6}
              desc={data11}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="इस app में merchant register कैसे कर सकता है"
              image={img6}
              desc={data12}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="Special Conditions for Merchant"
              image={img7}
              desc={data13}
            />
          </div>
          <div className="col-md-12 col-xl-6 col-sm-12">
            <Card
              title="Merchant के लिये विशेष शर्ते"
              image={img7}
              desc={data14}
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default About;
