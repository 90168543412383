import styled from "styled-components";
export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  }
`;

export const Heading = styled.div`
  padding: 20px;
  margin-top: 50px;
  text-align: center;
  background-color: teal;
  h1 {
    font-size: 50px;
    letter-spacing: 3px;
    font-weight: 600;
    line-height: 1rem;
    padding: 20px;
    color: white;
  }
`;
