import React from "react";
import {
  Button,
  Container,
  Title,
  Info,
  Image,
} from "../styledcomponents/home/CategoryItem";

const CategoryItem = ({ item }) => {
  return (
    <Container>
      <Image src={item.image} />
      <Info>
        <Title>{item.title}</Title>
        <Button>{item.btnText}</Button>
      </Info>
    </Container>
  );
};

export default CategoryItem;
