import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/UserList.module.css";
import Table from "../../../utils/Table";
import { Link } from "react-router-dom";
import { data } from "../../../data/RolelistData";
const RoleList = () => {
  const column = [
    {
      name: "USER ID",
      selector: (row) => row.userId,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "ROLE",
      selector: (row) => row.role,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "CONTACT NO.",
      selector: (row) => row.contactNo,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "ADDRESS",
      selector: (row) => row.address,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "CREATED DATE",
      selector: (row) => row.createdDate,
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "ACTION",
      cell: (row) => (
        <Link
          className="table-link "
          style={{ color: "red" }}
          title="delete Project"
        >
          <i className="bi bi-trash bi-stack-1x bi-inverse"></i>
        </Link>
      ),
      sortable: true,
      style: {
        justifyContent: "center",
      },
    },
  ];
  return (
    <AdminBase>
      <CustomSubHeading title="User Role List" />

      <div className={classes.container}>
        <div className={classes.userlist}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <Table columns={column} data={data} />
        </div>
      </div>
    </AdminBase>
  );
};

export default RoleList;
