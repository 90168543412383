import img1 from "../assests/images/Slider/image1.jpg";
import img2 from "../assests/images/Slider/image2.jpg";
import img3 from "../assests/images/Slider/sampleImg3.jpg";
export const data = [
  {
    id: 1,
    image: img1,
    title: "SUMMER SALE",
    desc: "DON'T COMPRIMSE ON STYLE! GET FLAT 30% OFF ON NEW ARRIVALS",
    btnText: "SHOP NOW",
    bg: "f5fafd",
  },
  {
    id: 2,
    image: img2,
    title: "SUMMER SALE",
    desc: "DON'T COMPRIMSE ON STYLE! GET FLAT 30% OFF ON NEW ARRIVALS",
    btnText: "SHOP NOW",
    bg: "f5fafd",
  },
  {
    id: 3,
    image: img3,
    title: "SUMMER SALE",
    desc: "DON'T COMPRIMSE ON STYLE! GET FLAT 30% OFF ON NEW ARRIVALS",
    btnText: "SHOP NOW",
    bg: "f5fafd",
  },
];
