import React from "react";
import classes from "../productsCard/ProductsCard.module.css";
import img from "../../assests/images/Shirts/Shirt1.jpg";
const MerchantProductCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.imgContainer}>
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
      </div>
      <div className={classes["details"]}>
        <table className={classes["detail-table"]}>
          <tbody>
            <tr>
              <td className={classes["first-column"]}>title</td>
              <td className={classes["second-column"]}>Ladies Kurti 1</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>shortDesc</td>
              <td className={classes["second-column"]}>Ladies Kurti Test</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>merchant</td>
              <td className={classes["second-column"]}>
                Prakash mens collection
              </td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>category</td>
              <td className={classes["second-column"]}>men</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>sub category</td>
              <td className={classes["second-column"]}>men</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>quantity</td>
              <td className={classes["second-column"]}>112</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>regular price</td>
              <td className={classes["second-column"]}>₹800</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>sale price</td>
              <td className={classes["second-column"]}>₹1000</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>discount</td>
              <td className={classes["second-column"]}>10</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>size</td>
              <td className={classes["second-column"]}>xl,xxl,s,m</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>color</td>
              <td className={classes["second-column"]}>red,green</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>approval</td>
              <td className={classes["second-column"]}>not approved</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MerchantProductCard;
