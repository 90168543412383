import React from "react";
import classes from "../../ui/volunteerCard/VolunteerListCard.module.css";
const CouponHistoryCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> COupon ID :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b>coupon amount :</b> <span>₹ 1000</span>{" "}
        </div>
        <div className={classes.div}>
          <b>Volunteer name :</b> <span>Anurag</span>
        </div>
        <div className={classes.div}>
          <b>contact number :</b> <span>+91 9876543210</span>
        </div>
        <div className={classes.div}>
          <b>username :</b> <span>Anurag</span>
        </div>
        <div className={classes.div}>
          <b>contact number :</b> <span>+91 9876543210</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>DKInfosolution</span>
          </div>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Dignissimos sint perferendis, quisquam maiores sapiente rem quam
              dolor. Asperiores, explicabo sint? Ratione, voluptate? Mollitia
              eum deserunt ea velit corrupti. Libero, tempore.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.btn} disabled>
          Action pending...
        </button>
      </div>
    </div>
  );
};

export default CouponHistoryCard;
