import React from "react";
import classes from "./PackageRequestCard.module.css";
const PackageRequestCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.username}>
        <div className={classes.username}>
          <h6>PACKAGE TYPE</h6>
        </div>
      </div>
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> requested by :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b> Status:</b> <span>Anurag kumar gupta</span>{" "}
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Dignissimos sint perferendis, quisquam maiores sapiente rem quam
              dolor. Asperiores, explicabo sint? Ratione, voluptate? Mollitia
              eum deserunt ea velit corrupti. Libero, tempore.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.btn} disabled>
          DEACTIVATE
        </button>
      </div>
    </div>
  );
};

export default PackageRequestCard;
