import React from "react";
import {
  Container,
  ImageContainer,
  Image,
  ScrollContainer,
} from "../styledcomponents/home/Brands";
import { data } from "../data/BrandsData";
import Marquee from "react-fast-marquee";

const Brands = () => {
  return (
    <Container>
      <Marquee vspace="5px">
        <ScrollContainer>
          {data.map((item) => (
            <ImageContainer key={item.id}>
              <Image src={item.image} />
            </ImageContainer>
          ))}
        </ScrollContainer>
      </Marquee>
    </Container>
  );
};

export default Brands;
