import React from "react";
import {
  Center,
  ContactItem,
  Container,
  Desc,
  Left,
  List,
  ListItem,
  Logo,
  Right,
  SocialContainer,
  SocialIcon,
  Title,
  Payment,
} from "../styledcomponents/home/Footer";
import { app_name } from "../utils/HelperText";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Room,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import payment from "../assests/images/payment.png";
const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>{app_name}</Logo>
        <Desc>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
          tristique orci vitae orci varius porttitor. Curabitur metus lorem,
          lacinia ac augue eget, venenatis porttitor neque. In convallis mattis
          consequat. Curabitur venenatis viverra enim id pharetra. Cras
          convallis faucibus nulla, a venenatis velit mollis tincidunt. In vitae
          egestas purus. Phasellus nibh risus, faucibus ac mauris in, mollis
          convallis mi. Nullam commodo elit vitae tortor malesuada, et semper
          felis viverra. Phasellus sagittis est et elit suscipit finibus.
        </Desc>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram />
          </SocialIcon>
          <SocialIcon color="55ACEE">
            <Twitter />
          </SocialIcon>
          <SocialIcon color="E60023">
            <WhatsApp />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <List>
          <ListItem>Home</ListItem>
          <ListItem>Cart</ListItem>
          <ListItem>Man Fashion</ListItem>
          <ListItem>Women Fashion</ListItem>
          <ListItem>Accessories</ListItem>
          <ListItem>My Account</ListItem>
          <ListItem>Order Tracking</ListItem>
          <ListItem>Wishlist</ListItem>
          <ListItem>Wishlist</ListItem>
          <ListItem>Terms</ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} /> Address
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} />
          Phone Number
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} />
          Email Address
        </ContactItem>
        <Payment src={payment} />
      </Right>
    </Container>
  );
};

export default Footer;
