export const data = [
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
  {
    userId: 112,
    name: "Anurag",
    email: "anurag@gmail.com",
    role: "user",
    contactNo: 1234567890,
    address: "Lucknow",
    createdDate: "2023-07-14",
  },
];
