import React from "react";
import classes from "./PackageCard.module.css";
const PackageCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.username}>
        <h6>Free Package</h6>
      </div>
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> type :</b> <span>free</span>
        </div>
        <div className={classes.div}>
          <b>Code :</b> <span>1112365</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>Description: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
              aliquam quibusdam iure sint quia tempore, adipisci corrupti id
              assumenda eum cumque quis. Voluptatibus veniam non, sapiente rerum
              ab laudantium nisi.
            </span>
          </div>
        </div>
        <div className={classes.div}>
          <b> value :</b> <span>0</span>
        </div>
        <div className={classes.div}>
          <b>Total Coupons :</b> <span>11</span>
        </div>
        <div className={classes.div}>
          <b> Total Product :</b> <span>12</span>
        </div>
        <div className={classes.div}>
          <b>Status :</b> <span>Active</span>
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.btn} disabled>
          DEACTIVATE
        </button>
      </div>
    </div>
  );
};

export default PackageCard;
