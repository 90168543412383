import React from "react";
import Announcement from "../../components/Announcement";
import { useState } from "react";
import CustomSidebar from "../../components/CustomSidebar";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import CustomNavbar from "../../components/CustomNavbar";

const Base = ({ title = "", children }) => {
  const [toggleState, setToggleState] = useState(true);
  const ontoggle = (data) => {
    setToggleState(data);
  };
  return (
    <>
      <Announcement />
      <CustomNavbar Toggle={ontoggle} />
      <CustomSidebar toggle={toggleState} />
      {children}
      <Newsletter />
      <Footer />
    </>
  );
};

export default Base;
