import styled, { keyframes } from "styled-components";

const scrollanimation = keyframes`
0% {
    transform:translateX(0px);
}
100% {
    transform:tranlateX(calc(-140px*11))
}
`;

export const Container = styled.div`
  height: 150px;
  margin: auto;
  width: 100%;
  place-items: center;
  display: grid;
`;
export const ScrollContainer = styled.div`
  display: flex;
  width: calc(140px * 22);
  animation: ${scrollanimation} 20s linear infinte;
`;

export const Heading = styled.div`
  padding: 20px;
  margin-top: 50px;
  text-align: center;
  background-color: teal;
  margin-bottom: 10px;
  h1 {
    font-size: 50px;
    letter-spacing: 5px;
    font-weight: 600;
    line-height: 1rem;
    padding: 20px;
    color: white;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  height: 100px;

  margin-right: 20px;
  background-color: white;
`;
export const Image = styled.img`
  object-fit: cover;
`;
