import React from "react";
import Base from "../Base";
import Joining from "./Joining";
import ActiveCoupons from "./ActiveCoupons";
import Gift from "./Gift";
import DonationPoints from "./DonationPoints";
import Merchants from "./Merchants";
import LuckyDraw from "./LuckyDraw";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/points&coupons/PointsNCoupons.module.css";
import { useEffect } from "react";
const Tabs = (props) => {
  let first = "";
  if (props.id === 1) {
    first = "defaultOpen";
  } else {
    first = `button${props.id}`;
  }
  console.log(props.id);
  return (
    <div className={classes["button-container"]}>
      <button
        className={classes["tablinks"]}
        onClick={(e) => openTab(e, props.id)}
        id={first}
      >
        {props.name}
      </button>
    </div>
  );
};
const openTab = (evt, tabId) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  tablinks = document.getElementsByClassName(classes.tablinks);
  console.log(tablinks);
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabId).style.display = "block";
  evt.currentTarget.className += " active";
};
// Get the element with id="defaultOpen" and click on it

const panels = [
  { id: 1, name: "Joining" },
  { id: 2, name: "Merchant" },
  { id: 3, name: "Gift" },
  { id: 4, name: "Active Coupons" },
  { id: 5, name: "Lucky Draw" },
  { id: 6, name: "Donation Points" },
];
const Index = () => {
  useEffect(() => {
    document.getElementById("defaultOpen").click();
  }, []);
  return (
    <Base>
      <CustomSubHeading title="points and Rewards" />
      <div className={classes.container}>
        <div className={classes.left}>
          {panels.map((tab) => (
            <Tabs name={tab.name} key={tab.id} id={tab.id} />
          ))}
        </div>
        <div className={classes.right}>
          <div className="tabcontent" id="1">
            <Joining />
          </div>
          <div className="tabcontent" id="2">
            <Merchants />
          </div>
          <div className="tabcontent" id="3">
            <Gift />
          </div>
          <div className="tabcontent" id="4">
            <ActiveCoupons />
          </div>
          <div className="tabcontent" id="5">
            <LuckyDraw />
          </div>
          <div className="tabcontent" id="6">
            <DonationPoints />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Index;
