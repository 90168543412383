import img1 from "../assests/images/Category/mens.jpg";
import img2 from "../assests/images/Category/women.jpg";
import img3 from "../assests/images/Category/children.jpg";

export const data = [
  {
    id: 1,
    image: img1,
  },
  {
    id: 2,
    image: img2,
  },
  {
    id: 3,
    image: img2,
  },
  {
    id: 4,
    image: img3,
  },
  {
    id: 5,
    image: img1,
  },
  {
    id: 6,
    image: img2,
  },
  {
    id: 7,
    image: img3,
  },
  {
    id: 8,
    image: img1,
  },
  {
    id: 9,
    image: img2,
  },
  {
    id: 10,
    image: img3,
  },
  {
    id: 11,
    image: img3,
  },
  {
    id: 12,
    image: img3,
  },
];
