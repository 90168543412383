import styled from "styled-components";
import login from "../../assests/images/register_bg.jpg";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${login}) center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  flex-direction: column;
`;
export const Link = styled.a`
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  padding: 20px;
  width: 25%;
  background-color: white;
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
  @media only screen and (min-width: 481px) {
    width: 90%;
  }
  @media only screen and (min-width: 767px) {
    width: 70%;
  }
  @media only screen and (min-width: 1024px) {
    width: 40%;
  }
  @media only screen and (min-width: 1281px) {
    width: 40%;
  }
`;
export const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
`;
export const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
`;
