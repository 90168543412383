import React from "react";
import { useParams } from "react-router-dom";
import MerchantProductCard from "../../ui/merchantProductCard/MerchantProductCard";
import Base from "../users/Base";
import CustomSubHeading from "../../components/CustomSubHeading";
import classes from "../../styledcomponents/admin/users/MerchantList.module.css";
import SplitCustomHeading from "../../components/SplitCustomHeading";

const MerchantProducts = () => {
  const param = useParams();

  return (
    <Base>
      <SplitCustomHeading type={param.type} />
      <CustomSubHeading title={`${param.type} Products`} />
      <div className={classes.container}>
        <div className={classes.merchant}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["merchant-container"]}>
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
            <MerchantProductCard />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default MerchantProducts;
