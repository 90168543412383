import styled from "styled-components";
export const Heading = styled.div`
  padding: 15px;
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: teal;
  h1 {
    // font-size: 50px;
    font-size: 30px;
    letter-spacing: 4px;
    font-weight: 600;
    // line-height: 1.5rem;

    color: white;
    // margin-top: 15px;
  }
`;
