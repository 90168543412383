import { myAxios } from "./BaseUrl";

//function to handle all get requests
export const getRequest = (url, header) => {
  return myAxios
    .get(`${url}`, { headers: header })
    .then((response) => response);
};

//function to handle all post request
export const postRequest = (url, data, header) => {
  return myAxios
    .post(`${url}`, data, { headers: header })
    .then((response) => response);
};
