import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/category/SubCategoryList.module.css";
import img from "../../../assests/images/Shirts/Shirt1.jpg";
import SubCategoryListCard from "../../../ui/subCategorylistCard/SubCategoryListCard";
const SubCategoryList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="sub category list" />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <SubCategoryListCard img={img} title="Sub Cat 1" />
          <SubCategoryListCard img={img} title="Sub Cat 1" />
          <SubCategoryListCard img={img} title="Sub Cat 1" />
          <SubCategoryListCard img={img} title="Sub Cat 1" />
        </div>
      </div>
    </AdminBase>
  );
};

export default SubCategoryList;
