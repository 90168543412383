import React from "react";
import classes from "../../../styledcomponents/points&coupons/PointsNCoupons.module.css";
import DataTable from "react-data-table-component";
const ActiveCoupons = () => {
  const columns = [
    {
      name: "COUPON ID",
    },
    {
      name: "GIVEN TO",
    },
    {
      name: "PHONE NUMBER",
    },
    {
      name: "CREATED ON",
    },
    {
      name: "EXPIRY DATE",
    },
  ];
  return (
    <div className={classes["joining-container"]}>
      <div className={classes["joining-points"]}>
        <h3>Coupon History </h3>
      </div>
      <div className={classes["joining-point-history"]}>
        <DataTable columns={columns} />
      </div>
    </div>
  );
};

export default ActiveCoupons;
