import { ErrorMsg, successMsg } from "../utils/Messages";

export const StatusCheck = (response) => {
  const statusCode = response.status;
  const status = response.data.status;
  switch (statusCode) {
    case 200: {
      if (status === "Success" || status === "success") {
        return true;
      } else {
        ErrorMsg(response.data.message);
        return false;
      }
    }
    case 201: {
      successMsg(response.data.message);
      return true;
    }
    case 400: {
      ErrorMsg(response.data.message);
      return false;
    }
    case 401: {
      ErrorMsg("Your Session is Expired! Please login Again");
      return false;
    }
    default: {
      ErrorMsg("Some Thing Went Wrong Please Try again");
    }
  }
};
