import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/MerchantList.module.css";
import CouponHistoryCard from "../../../ui/couponHistoryCard/CouponHistoryCard";

const VolCouponHis = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Coupon history" />
      <div className={classes.container}>
        <div className={classes.merchant}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["merchant-container"]}>
            <CouponHistoryCard />
            <CouponHistoryCard />
            <CouponHistoryCard />
            <CouponHistoryCard />
            <CouponHistoryCard />
            <CouponHistoryCard />
            <CouponHistoryCard />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default VolCouponHis;
