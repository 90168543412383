import React from "react";
import classes from "../../../styledcomponents/points&coupons/PointsNCoupons.module.css";
import DataTable from "react-data-table-component";

const Merchants = () => {
  const columns = [
    {
      name: "FROM",
    },
    {
      name: "POINTS",
    },
    {
      name: "DESCRIPTION",
    },
    {
      name: "CREATED AT",
    },
    {
      name: "EXPIRY DATE",
    },
  ];
  return (
    <div className={classes["joining-container"]}>
      <div className={classes["joining-points"]}>
        <h3>Total Merchant Points </h3>
        <h2>2000</h2>
      </div>
      <div className={classes["joining-point-history"]}>
        <DataTable columns={columns} />
      </div>
    </div>
  );
};

export default Merchants;
