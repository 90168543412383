import axios from "axios";
//for developement Server
const base_url = "http://localhost:80/";
//for production Server
//const base_url=""
//for Staging Server
//const base_url = "http://192.168.29.254/";
export const myAxios = axios.create({
  baseURL: base_url,
});
