import { toast } from "react-toastify";

export const successMsg = (msg) =>
  toast.success(msg, { position: "top-center" });

export const ErrorMsg = (msg) => toast.error(msg, { position: "top-center" });

export const ServerDown = () => {
  toast.info("Server Down Please Try Again", { position: "top-center" });
};
export const FullName = "Please Enter Name";

export const Title = "Please Enter Title";

export const Category = "Please Enter Category";

export const SubCategoryName = "Please Enter SubCategory Name";
export const CategorySelect = "Please Select Category";

export const Description = "Please Enter Description";

export const Icon = "Please Select Image File";

export const UnsupportedFile = [
  "Please Select a File",
  "Unsupported Media Type",
];
