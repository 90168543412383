import React from "react";
import classes from "../styledcomponents/home/ProductCard.module.css";
import { Rating } from "@mui/material";
import {
  FavoriteBorderOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
const ProductCard = ({ item }) => {
  return (
    <div className={classes.card}>
      <img src={item.image} alt="" className={classes.img} />
      <div className={classes.info}>
        <div className={classes.icon}>
          <ShoppingCartOutlined />
        </div>
        <div className={classes.icon}>
          <FavoriteBorderOutlined />
        </div>
      </div>
      <div className={classes.detail}>
        <div className={classes.Rating}>
          <h5>Ratings </h5>
          <Rating
            name="read-only"
            value={4}
            readOnly
            className={classes.rating}
            style={{ fontSize: 16 }}
          />
        </div>
        <h4>$1200</h4>
      </div>
    </div>
  );
};

export default ProductCard;
