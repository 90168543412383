import styled from "styled-components";
export const Container = styled.div`
  height: 3.75rem;
  margin-bottom: 0.313rem;
`;

export const Wrapper = styled.div`
  padding: 10px 20px;
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
`;
export const Center = styled.div`
  flex: 1;
  text-align: center;
`;
export const Right = styled.div`
  flex: 1;
  display: flex;
  align-item: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
`;
export const SearchContainer = styled.div`
  border: 1px solid grey;
  display: flex;
  align-items: center;
  margin-left: 1.563rem;
  padding: 5px;
  margin-top: 10px;
`;
export const Input = styled.input`
  border: none;
`;
export const Logo = styled.h1`
  font-weight: bold;
  margin: 0;
`;

export const MenuItem = styled.div`
    font size:14px;
    cursor:pointer;
    margin-left:25px;
`;
export const LogoImage = styled.img`
  height: 3.125rem;
  width: 3.125rem;
  margin-right: 10px;
`;
export const MenuSubItem = styled.ul`
  position: abolute;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100px;
`;

export const DropdownMenuContainer = styled.div`
  position: absolute;
  z-index: 100;
  right: 20px;

  width: 150px;
  border: 2px solid black;
  background-color: teal;
`;
export const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
`;
export const DropdownMenuItem = styled.li`
  line-height: 2rem;
  font-size: 16px;
  font-weight: 500;
  padding-left: 15px;
  &:hover {
    background-color: aliceblue;
    color: black;
  }
`;

export const HR = styled.div`
  width: 100%;
  height: 2px;
  background-color: gray;
`;
