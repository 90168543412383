import React from "react";
import { Container } from "../styledcomponents/home/Products";
import { data } from "../data/ProductData";
import SlashedPriceItem from "./SlashedPriceItem";
const SlashedPrice = () => {
  return (
    <Container>
      {data.map((output) => (
        <SlashedPriceItem item={output} key={output.id} />
      ))}
    </Container>
  );
};

export default SlashedPrice;
