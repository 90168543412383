import React from "react";
import AdminBase from "../AdminBase";
import { Form } from "../../../styledcomponents/login/Login";
import { InputAdornment, TextField } from "@mui/material";
import { InsertDriveFile, Title } from "@mui/icons-material";
import classes from "../../../styledcomponents/admin/category/AddCategory.module.css";
import CustomSubHeading from "../../../components/CustomSubHeading";
import { useEffect } from "react";
import { useState } from "react";
import { getRequest, postRequest } from "../../../network/Services";
import { category, subCategory, uploadFile } from "../../../network/APIUrls";
import {
  headerWithToken,
  headerWithTokenwithMultiPart,
} from "../../../network/Headers";
import { AddSubCategoryValidation } from "../../../validations/Category";
import { StatusCheck } from "../../../network/ResponseStatus";
import GlobalSpinners from "../../../utils/spinners/GlobalSpinners";
import { ServerDown } from "../../../utils/Messages";
const AddSubCategory = () => {
  const [spinner, setSpinner] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  //Used to get list of all Category

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllCategory();
    }, 50);
    //Clean up function
    return () => {
      clearTimeout(timer);
    };
  }, []);
  //function to get all category
  const getAllCategory = () => {
    getRequest(category, headerWithToken()).then((response) => {
      setAllCategory(response.data.Data);
    });
  };

  //function handling submit form to add subcategory
  const formhandler = (event) => {
    event.preventDefault();
    const category = event.target.add_sub_category.value;
    const description = event.target.description.value;
    const subcategoryName = event.target.title.value;
    const icon = event.target.icon.files[0];
    const data = {
      category,
      description,
      subcategoryName,
    };
    if (AddSubCategoryValidation(data)) {
      //api call to upload files
      const formData = new FormData();
      formData.append("file", icon);
      postRequest(uploadFile, formData, headerWithTokenwithMultiPart())
        .then((response) => {
          if (StatusCheck(response)) {
            const filename = response.data.fileName;
            //api call to submit complete subcategory
            postRequest(subCategory, { ...data, filename }, headerWithToken())
              .then((response) => {
                if (StatusCheck(response)) {
                  setTimeout(() => {
                    setSpinner(false);
                  }, 1500);
                }
              })
              .catch((error) => {
                if (error.response === undefined) {
                  ServerDown();
                  setSpinner(false);
                } else {
                  StatusCheck(error.response);
                }
              });
          }
        })
        .catch((error) => {
          if (error.response === undefined) {
            ServerDown();
            setSpinner(false);
          } else {
            StatusCheck(error.response);
          }
        });
      //reset form on successful submit
      document.getElementById("form").reset();
    } else {
      setSpinner(false);
    }
  };
  return (
    <AdminBase>
      {spinner && <GlobalSpinners />}
      <CustomSubHeading title="Add sub category" />
      <div className={classes.category}>
        <div className={classes["category-wrapper"]}>
          <Form onSubmit={formhandler} id="form">
            <select
              className={classes.select}
              name="add_sub_category"
              id="add_sub_category"
            >
              <option value="">---SELECT CATEGORY---</option>
              {allCategory.length > 0 ? (
                allCategory.map((category) => (
                  <option value={category.categoryId} key={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
            <TextField
              name="title"
              id="subcategory_title"
              label="TITLE"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="description"
              id="subcategory_desc"
              label="DESCRIPTION"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="icon"
              id="category_icon"
              label="ICON"
              variant="outlined"
              type="file"
              inputProps={{ accept: "image/jpeg,image/png,image/jpg" }}
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertDriveFile />
                  </InputAdornment>
                ),
              }}
            />
            <button className={classes.btn} type="submit">
              ADD
            </button>
          </Form>
        </div>
      </div>
    </AdminBase>
  );
};

export default AddSubCategory;
