import React from "react";
import video from "../assests/videos/background.mp4";
import classes from "./LuckyDraw.module.css";
import Spinner from "../utils/spinners/Spinner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const LuckyDrawStartup = () => {
  const [spinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const handlePageTransition = () => {
    setShowSpinner(true);
    setTimeout(() => {
      navigate("/admin");
    }, 2000);
  };
  return (
    <div className={classes.background}>
      <video src={video} autoPlay loop muted className={classes.video} />
      <div class={classes["content"]}>
        <h1>Your journey to luck starts here!</h1>
        <button id="myBtn" onClick={handlePageTransition}>
          Press Here!
        </button>
        {spinner && <Spinner />}
      </div>
    </div>
  );
};

export default LuckyDrawStartup;
