import React from "react";
import { Fragment } from "react";
import PreLoader from "../../../animations/preloader/PreLoader";
import AdminBase from "../AdminBase";
import classes from "../../../styledcomponents/admin/users/MerchantList.module.css";
import CustomSubHeading from "../../../components/CustomSubHeading";
import DonateCard from "../../../ui/donateCard/DonateCard";
const Donate = () => {
  return (
    <Fragment>
      <PreLoader />
      <AdminBase>
        <CustomSubHeading title="Donate" />
        <div className={classes.container}>
          <div className={classes.merchant}>
            <div className="text-end mb-3 mt-2">
              <input
                type="text"
                placeholder="Search by Project"
                className={classes["search-input"]}
              ></input>
            </div>
            <div className={classes["merchant-container"]}>
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
              <DonateCard />
            </div>
          </div>
        </div>
      </AdminBase>
    </Fragment>
  );
};

export default Donate;
