import React from "react";
import CustomHeading from "../../components/CustomHeading";
import { Container, Wrapper } from "../../styledcomponents/admin/CustomSidebar";
import classes from "../../styledcomponents/admin/Contact.module.css";
import { PermPhoneMsg } from "@mui/icons-material";
import Base from "../users/Base";
const Contact = () => {
  return (
    <Base>
      <CustomHeading title="CONTACT US" />
      <Container>
        <Wrapper>
          <PermPhoneMsg
            style={{
              fontSize: 70,
              marginBottom: 50,
              marginLeft: "45%",
              color: "midnightblue",
            }}
          />
          <h1 className={classes.title}>
            For any support, please contact below details:
          </h1>
          <h4 className={classes.email}>
            <span className={classes["email-title"]}>EMAIL </span>
            <span className={classes["email-text"]}>
              humaraebazar@gmail.com
            </span>
          </h4>
          <h4 className={classes.contact}>
            <span className={classes["email-title"]}>CONTACT NO. </span>
            <span className={classes["contact-no"]}>+91-9936331200</span>
          </h4>
        </Wrapper>
      </Container>
    </Base>
  );
};

export default Contact;
