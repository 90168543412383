//Auth Urls
export const login = "login/";
export const signup = "signup/";

//Category and subCategory Urls
export const category = "category";
export const subCategory = "subcategory";
//File Upload Url
export const uploadFile = "/file/";
export const uploadProduct = "/file/products/";

//Products Urls
export const products = "product";
