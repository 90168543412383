import React, { useState } from "react";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import {
  Container,
  Arrow,
  Wrapper,
  Info,
  Image,
  Title,
  Description,
  Button,
  ImageContainer,
  Slide,
} from "../styledcomponents/home/Slider";
import { data } from "../data/SliderData";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };
  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowBackIosNewOutlined
          style={{ color: "white", fontSize: "50px", fontWeight: "900" }}
        />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {data.map((output) => (
          <Slide bg={output.bg} key={output.id}>
            <ImageContainer>
              <Image src={output.image} />
            </ImageContainer>
            {/* <Info>
              <Title>{output.title}</Title>
              <Description>{output.desc}</Description>
              <Button>{output.btnText}</Button>
            </Info> */}
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowForwardIosOutlined
          style={{ color: "white", fontSize: "50px", fontWeight: "900" }}
        />
      </Arrow>
    </Container>
  );
};

export default Slider;
