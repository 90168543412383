import React from "react";
import { Heading } from "../styledcomponents/home/CustomSubHeading";
import { motion as m } from "framer-motion";

const CustomSubHeading = (props) => {
  return (
    <m.div
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.7 }}
    >
      <Heading>
        <h1>{props.title.toUpperCase()}</h1>;
      </Heading>
    </m.div>
  );
};

export default CustomSubHeading;
