import React from "react";
import classes from "./Spinner.module.css";
import { motion as m } from "framer-motion";

const Spinner = () => {
  return (
    <div className={classes["progressbar-container"]}>
      <div className={classes.progressbar}>
        <m.div
          className={classes.bar}
          initial={{ width: "0%" }}
          animate={{ width: `100%` }}
          transition={{ duration: 2 }}
        ></m.div>
      </div>
    </div>
  );
};

export default Spinner;
