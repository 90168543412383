import React from "react";
import classes from "./NoDataCard.module.css";
const NoDataCard = () => {
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h5>No Data Available</h5>
      </div>
    </div>
  );
};

export default NoDataCard;
