import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import PackageHistoryCard from "../../../ui/packageHistoryCard/PackageHistoryCard";
import classes from "../../../styledcomponents/admin/users/MerchantList.module.css";

const PackageBuyHistory = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Package Purchased" />
      <div className={classes.container}>
        <div className={classes.merchant}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["merchant-container"]}>
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
            <PackageHistoryCard />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default PackageBuyHistory;
