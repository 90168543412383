import React from "react";
import {
  Button,
  Container,
  Div,
  Form,
  Input,
  Link,
  Title,
  Wrapper,
} from "../styledcomponents/login/Login";
import {
  Company,
  CompanyName,
  Logo,
} from "../styledcomponents/register/Register";
import logo from "../assests/images/ShoffersLogo.png";
import { app_name } from "../utils/HelperText";
import { useNavigate } from "react-router-dom";

const MLogin = () => {
  const Navigate = useNavigate();

  return (
    <Container>
      <Company>
        <Logo src={logo} />
        <CompanyName onClick={() => Navigate("/")}>{app_name}</CompanyName>
      </Company>
      <Wrapper>
        <Title>SIGN IN</Title>
        <Form>
          <Input placeholder="Mobile Number" />
          <Button>SEND OTP</Button>
          <Div>
            <Link onClick={() => Navigate("/login")}>LOGIN USING PASSWORD</Link>
            <Link onClick={() => Navigate("/resetpassword")}>
              DO NOT REMEMBER PASSWORD
            </Link>
          </Div>
          <Link onClick={() => Navigate("/register")}>CREATE AN ACCOUNT</Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default MLogin;
