import React from "react";
import resetpassword from "../assests/images/resetpassword.jpg";
import {
  Button,
  Form,
  Input,
  Link,
  Title,
  Wrapper,
} from "../styledcomponents/login/Login";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Company,
  CompanyName,
  Logo,
} from "../styledcomponents/register/Register";
import { app_name } from "../utils/HelperText";
import logo from "../assests/images/ShoffersLogo.png";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${resetpassword}) center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  flex-direction: column;
`;
const ResetPassword = () => {
  const Navigate = useNavigate();
  return (
    <Container>
      <Company>
        <Logo src={logo} />
        <CompanyName onClick={() => Navigate("/")}>{app_name}</CompanyName>
      </Company>
      <Wrapper>
        <Title>RESET PASSWORD</Title>
        <Form>
          <Input placeholder="ENTER PHONE NUMBER" type="text" />
          <Button>SEND OTP</Button>
          <Link onClick={() => Navigate("/login")}>LOGIN</Link>
          <Link onClick={() => Navigate("/register")}>CREATE AN ACCOUNT</Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default ResetPassword;
