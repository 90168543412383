import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/category/AddCategory.module.css";
import { Form } from "../../../styledcomponents/login/Login";
import { TextField, InputAdornment } from "@mui/material";
import {
  AtmOutlined,
  CurrencyRupee,
  Description,
  Details,
  InsertDriveFile,
  Numbers,
  Title,
} from "@mui/icons-material";

const AssignCouponToVol = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="COUPON ASSIGNMENT" />
      <div className={classes.category}>
        <div className={classes["category-wrapper"]}>
          <Form>
            <select className={classes.select} name="volunteer" id="volunteer">
              <option value="">---VOLUNTEER---</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
            </select>
            <select className={classes.select} name="sponsor" id="sponsor">
              <option value="">---SELECT CATEGORY---</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
            </select>
            <TextField
              id="coupon-amount"
              label="COUPON AMOUNT"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupee />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="subcategory-icon"
              label="NUMBER OF COUPON"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Numbers />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="subcategory-icon"
              label="DESCRIPTION"
              variant="outlined"
              multiline
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />
          </Form>
          <button className={classes.btn}>ADD</button>
        </div>
      </div>
    </AdminBase>
  );
};

export default AssignCouponToVol;
