import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/VolunteerList.module.css";
import VolRequestListCard from "../../../ui/volunteerRequestListCard/VolRequestListCard";

const VolunteerRequestList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Volunteer request list" />
      <div className={classes.container}>
        <div className={classes.volunteer}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["volunteer-container"]}>
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
            <VolRequestListCard />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default VolunteerRequestList;
