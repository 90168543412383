import React from "react";
import { Container } from "../styledcomponents/home/Announcement";
import Marquee from "react-fast-marquee";
const Announcement = () => {
  return (
    <Container>
      <Marquee vspace="5px">
        I can be a React component, multiple React components, or just some
        text.
      </Marquee>
    </Container>
  );
};

export default Announcement;
