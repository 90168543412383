import { getCurrentUserToken } from "./SessionStorage";

//headers for request not requiring Authorization or Token value
export const headerWithoutToken = () => {
  const head = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return head;
};

//headers for request requiring Authorization or Token value
export const headerWithToken = () => {
  const head = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCurrentUserToken()}`,
  };
  return head;
};

//headers for request requiring file upload
export function headerWithTokenwithMultiPart() {
  const head = {
    Authorization: `Bearer ${getCurrentUserToken()}`,
  };

  return head;
}
