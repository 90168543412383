import {
  Category,
  CategorySelect,
  Description,
  Title,
  UnsupportedFile,
} from "../utils/Messages";
import { File, Value } from "./Common";
export const AddCategoryValidation = ({
  categoryName,
  description,
  categoryFile,
}) => {
  if (
    Value(categoryName, Category) ||
    Value(description, Description) ||
    File(categoryFile, UnsupportedFile)
  ) {
    return false;
  } else {
    return true;
  }
};

export const AddSubCategoryValidation = ({
  category,
  description,
  subcategoryName,
  icon,
}) => {
  if (
    Value(category, CategorySelect) ||
    Value(subcategoryName, Title) ||
    Value(description, Description) ||
    File(icon, UnsupportedFile)
  ) {
    return false;
  } else {
    return true;
  }
};
