import React from "react";
import { Container } from "../styledcomponents/home/Products";
import { data } from "../data/ProductData";
import ProductCard from "./ProductCard";
import { useNavigate } from "react-router-dom";

const AllProducts = () => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate("/product")}>
      {data.map((output) => (
        <ProductCard item={output} key={output.id} />
      ))}
    </Container>
  );
};

export default AllProducts;
