import React from "react";
import classes from "./ProductsCard.module.css";
import img from "../../assests/images/Shirts/Shirt1.jpg";
const ProductsCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.imgContainer}>
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
        <img src={img} alt="" className={classes.img} />
      </div>
      <div className={classes.details}>
        <table className={classes["detail-table"]}>
          <tbody>
            <tr>
              <td className={classes["first-column"]}>title</td>
              <td className={classes["second-column"]}>112</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>SKU</td>
              <td className={classes["second-column"]}>112</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>merchant</td>
              <td className={classes["second-column"]}>
                Prakash mens collection
              </td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>category</td>
              <td className={classes["second-column"]}>men</td>
            </tr>

            <tr>
              <td className={classes["first-column"]}>quantity</td>
              <td className={classes["second-column"]}>112</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>regular price</td>
              <td className={classes["second-column"]}>₹800</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>sale price</td>
              <td className={classes["second-column"]}>₹1000</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>status</td>
              <td className={classes["second-column"]}>Active</td>
            </tr>
            <tr>
              <td className={classes["first-column"]}>approval</td>
              <td className={classes["second-column"]}>not approved</td>
            </tr>
          </tbody>
        </table>

        <div className={classes.footer}>
          <button>Approve</button>
          <button>Reject</button>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
