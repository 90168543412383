import React from "react";
import AdminBase from "../AdminBase";
import classes from "../../../styledcomponents/admin/users/MerchantList.module.css";
import CustomSubHeading from "../../../components/CustomSubHeading";
import PackageCard from "../../../ui/packageCard/PackageCard";
import { motion as m } from "framer-motion";

const PackageList = () => {
  return (
    <m.div
      initial={{ x: "+100vw" }}
      animate={{ x: "0" }}
      // exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.7 }}
    >
      <AdminBase>
        <CustomSubHeading title="Package list" />
        <div className={classes.container}>
          <div className={classes.merchant}>
            <div className="text-end mb-3 mt-2">
              <input
                type="text"
                placeholder="Search by Project"
                className={classes["search-input"]}
              ></input>
            </div>
            <div className={classes["merchant-container"]}>
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
              <PackageCard />
            </div>
          </div>
        </div>
      </AdminBase>
    </m.div>
  );
};

export default PackageList;
