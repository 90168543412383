import React from "react";
import { ThreeCircles } from "react-loader-spinner";
import classes from "./GlobalSpinner.module.css";
import { createPortal } from "react-dom";
const Backdrop = () => {
  return <div className={classes.backdrop}></div>;
};

const Overlay = () => {
  return (
    <div className={classes.modal}>
      <ThreeCircles
        height="50"
        width="50"
        // color="#4fa94d"
        color="#624da9"
        wrapperStyle={{}}
        wrapperClass={{}}
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );
};

const GlobalSpinners = () => {
  return (
    <>
      {createPortal(<Backdrop />, document.getElementById("spinner-backdrop"))}
      {createPortal(<Overlay />, document.getElementById("spinner-overlay"))}
    </>
  );
};

export default GlobalSpinners;
