import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  position: relative;
  overflow: hidden;
`;
export const Arrow = styled.div`
  width: 50px;
  height: 50px;
  bacground-color: white;
  background-radius: 50%;
  justify-content:center;
  align-items:center:
  display:flex;
  position:absolute;
  top:0;
  bottom:0;
  left:${(props) => props.direction === "left" && "25px"};
  right:${(props) => props.direction === "right" && "10px"};
  margin:auto;
  cursor:pointer;
  opacity:0.5;
  z-index:2;
`;

export const Wrapper = styled.div`
  height: 80%;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
  display: flex;
  transition: all 1.5s ease;
`;

export const Slide = styled.div`
  display: flex;
  width: 100vw;
  height: 80vh;
  align-items: center;
  background-color: #${(props) => props.bg};
`;
export const ImageContainer = styled.div`
  height: 100%;
  flex: 1;
`;
export const Info = styled.div`
  flex: 1;
  padding: 50px;
`;
export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Title = styled.h1`
  font-size: 70px;
`;
export const Description = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
`;

export const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;
