import React from "react";
import {
  Button,
  Container,
  Div,
  Form,
  Input,
  Link,
  Title,
  Wrapper,
} from "../styledcomponents/login/Login";
import { useNavigate } from "react-router-dom";
import {
  Company,
  CompanyName,
  Logo,
} from "../styledcomponents/register/Register";
import { app_name } from "../utils/HelperText";
import logo from "../assests/images/ShoffersLogo.png";
import { headerWithoutToken } from "../network/Headers";
import { useRef } from "react";
import { LoginValidations } from "../validations/Auth";
import { postRequest } from "../network/Services";
import { login } from "../network/APIUrls";
import { StatusCheck } from "../network/ResponseStatus";
import { doLogin, getCurrentUserRole } from "../network/SessionStorage";
import { useState } from "react";
import GlobalSpinners from "../utils/spinners/GlobalSpinners";
import { ServerDown, successMsg } from "../utils/Messages";
const Login = () => {
  const [spinner, setSpinner] = useState(false);
  const Navigate = useNavigate();
  //references for input data
  const usernameRef = useRef();
  const passwordRef = useRef();

  //login function
  const handleLogin = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const userName = usernameRef.current.value;
    const password = passwordRef.current.value;
    const data = { userName, password };
    if (LoginValidations(data)) {
      postRequest(login, data, headerWithoutToken())
        .then((response) => {
          if (StatusCheck(response)) {
            doLogin(response.data);
            setTimeout(() => {
              setSpinner(false);
            }, 3000);
            setTimeout(() => {
              if (getCurrentUserRole() === "ADMIN") {
                Navigate("/admin");
              } else {
                Navigate("/");
              }
            }, 1000);
            setTimeout(() => {
              successMsg("Login Successful");
            }, 2000);
          }
        })
        // in case there is a error from server side or server is down
        .catch((error) => {
          if (error.response === undefined) {
            ServerDown();
            setSpinner(false);
          } else {
            StatusCheck(error.response);
          }
        });
    }
    // if case validation is false
    else {
      setSpinner(false);
    }
  };

  return (
    <Container>
      {spinner && <GlobalSpinners />}
      <Company>
        <Logo src={logo} />
        <CompanyName onClick={() => Navigate("/")}>{app_name}</CompanyName>
      </Company>
      <Wrapper>
        <Title>SIGN IN</Title>
        <Form>
          <Input
            placeholder="username"
            type="text"
            ref={usernameRef}
            autoComplete="on"
          />
          <Input
            placeholder="password"
            type="password"
            ref={passwordRef}
            autoComplete="off"
          />
          <Button onClick={handleLogin}>LOGIN</Button>
          <Div>
            <Link onClick={() => Navigate("/mlogin")}>LOGIN USING PIN</Link>
            <Link onClick={() => Navigate("/resetpassword")}>
              DO NOT REMEMBER PASSWORD
            </Link>
          </Div>
          <Link onClick={() => Navigate("/register")}>CREATE AN ACCOUNT</Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Login;
