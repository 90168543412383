import React, { useEffect, useState } from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/VolunteerList.module.css";
import ProductsCard from "../../../ui/productsCard/ProductsCard";
import { headerWithToken } from "../../../network/Headers";
import { getRequest } from "../../../network/Services";
import { products } from "../../../network/APIUrls";
import NoDataCard from "../../../ui/noDataCard/NoDataCard";

const Products = () => {
  const [allProducts, setAllProducts] = useState([]);
  //Used to get list of all Products
  useEffect(() => {
    return () => {
      const timer = setTimeout(() => {
        getAllProducts();
      }, 50);
      //Clean up function
      clearTimeout(timer);
    };
  }, []);

  //function to get all Products
  const getAllProducts = () => {
    getRequest(products, headerWithToken()).then((response) => {
      setAllProducts(response.data.Data);
    });
  };
  return (
    <AdminBase>
      <CustomSubHeading title="Products List" />
      <div className={classes.container}>
        <div className={classes.volunteer}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["volunteer-container"]}>
            {allProducts.length > 0 ? (
              allProducts.map((product) => (
                <ProductsCard key={product.productId} data={product} />
              ))
            ) : (
              <NoDataCard />
            )}
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default Products;
