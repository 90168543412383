import React from "react";
import classes from "../styledcomponents/admin/CustomSidebar.module.css";
import {
  ArrowForwardIos,
  Brightness1Sharp,
  Category,
  ContactSupport,
  CurrencyBitcoinSharp,
  HomeSharp,
  Info,
  Person2,
  PointOfSale,
  WidgetsSharp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getCurrentUserRole, isLoggedIn } from "../network/SessionStorage";
const CustomSidebar = (props) => {
  return (
    <>
      <aside
        id="sidebar"
        className={
          props.toggle ? classes["toggle-sidebar"] : classes["sidebar"]
        }
      >
        <ul className={classes["sidebar-nav"]} id="sidebar-nav">
          <li className={classes["nav-item"]}>
            <Link className={classes["nav-link"]} to="/">
              <HomeSharp className={classes.icons} />
              <span>HOME</span>
            </Link>
          </li>
          <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/productlist">
              <WidgetsSharp className={classes.icons} />
              <span>PRODUCTS</span>
            </Link>
          </li>
          {/* Category module start */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#forms-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Category className={classes.icons} />
              <span>CATEGORY</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="forms-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/category/addcategory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>MEN</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/category/categorylist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>WOMEN</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/category/subcategory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>CHILDREN</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* end of category module */}
          {isLoggedIn() && (
            <>
              <li className={classes["nav-item"]}>
                <Link
                  className={`${classes["nav-link"]}`}
                  to="/pointsandcoupons"
                >
                  <PointOfSale className={classes.icons} />
                  <span>POINTS AND COUPON</span>
                </Link>
              </li>
              {getCurrentUserRole() !== "MERCENT" ? (
                <li className={classes["nav-item"]}>
                  <Link
                    className={`${classes["nav-link"]}`}
                    to="/becomemerchants"
                  >
                    <CurrencyBitcoinSharp className={classes.icons} />
                    <span>BECOME MERCHANT</span>
                  </Link>
                </li>
              ) : (
                //Merchants extra previleges
                <li className={classes["nav-item"]}>
                  <Link
                    className={`${classes["nav-link"]} collapsed`}
                    data-bs-target="#forms-nav"
                    data-bs-toggle="collapse"
                    to="#"
                  >
                    <CurrencyBitcoinSharp className={classes.icons} />
                    <span>MERCHANTS</span>
                    <ArrowForwardIos className={classes["bi-chevron-down"]} />
                  </Link>
                  <ul
                    id="forms-nav"
                    className={`${classes["nav-content"]} collapse`}
                    data-bs-parent="#sidebar-nav"
                  >
                    <li>
                      <Link to="/addproducts">
                        <Brightness1Sharp className={classes.circle} />
                        <span>ADD PRODUCTS</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchantproducts/active">
                        <Brightness1Sharp className={classes.circle} />
                        <span>ACTIVE PRODUCTS</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchantproducts/inactive">
                        <Brightness1Sharp className={classes.circle} />
                        <span>INACTIVE PRODUCTS</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/packages">
                        <Brightness1Sharp className={classes.circle} />
                        <span>PACKAGES</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                //end of merchants previleges
              )}
            </>
          )}
          <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/about">
              <Info className={classes.icons} />
              <span>ABOUT</span>
            </Link>
          </li>
          <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/contact">
              <ContactSupport className={classes.icons} />
              <span>CONTACT</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default CustomSidebar;
