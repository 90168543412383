import React from "react";
import Slider from "../components/Slider";
import Category from "../components/Category";
import Brands from "../components/Brands";
import CustomHeading from "../components/CustomHeading";
import Base from "./users/Base";
import NewArrivals from "../components/NewArrivals";
import SubCategory from "../components/SubCategory";
import SlashedPrice from "../components/SlashedPrice";
import Products from "./admin/products/Products";

const Home = () => {
  return (
    <Base>
      <Slider />
      <CustomHeading title="SHOP BY CATEGORY" />
      <Category />
      <CustomHeading title="OUR MERCHANTS" />
      <Brands />
      <CustomHeading title="NEW ARRIVALS" />
      <NewArrivals />
      <CustomHeading title="Categories to Bag" />
      <SubCategory />
      <CustomHeading title="Products At Slashed Price" />
      <SlashedPrice />
      {/* <CustomHeading title="PRODUCTS" />
      <Products /> */}
    </Base>
  );
};

export default Home;
