import React from "react";
import {
  Button,
  Container,
  Form,
  Input,
  Link,
  Wrapper,
} from "../styledcomponents/login/Login";
import {
  Company,
  CompanyName,
  Logo,
} from "../styledcomponents/register/Register";
import { app_name } from "../utils/HelperText";
import { useNavigate } from "react-router-dom";
import logo from "../assests/images/ShoffersLogo.png";

const OPT = () => {
  const Navigate = useNavigate();
  return (
    <Container>
      <Company>
        <Logo src={logo} />
        <CompanyName onClick={() => Navigate("/")}>{app_name}</CompanyName>
      </Company>
      <Wrapper>
        <Form>
          <Input placeholder="ENTER OTP"></Input>
          <Button>LOGIN</Button>
          <Link>RESEND OTP</Link>
          <Link onClick={() => Navigate("/mlogin")}>BACK TO LOGIN</Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default OPT;
