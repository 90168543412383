import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/users/VolunteerList.module.css";
import VolunteerListCard from "../../../ui/volunteerCard/VolunteerListCard";

const VolunteerList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="volunteer List" />

      <div className={classes.container}>
        <div className={classes.volunteer}>
          <div className="text-end mb-3 mt-2">
            <input
              type="text"
              placeholder="Search by Project"
              className={classes["search-input"]}
            ></input>
          </div>
          <div className={classes["volunteer-container"]}>
            <VolunteerListCard />
            <VolunteerListCard />
            <VolunteerListCard />
            <VolunteerListCard />
            <VolunteerListCard />
            <VolunteerListCard />
            <VolunteerListCard />
          </div>
        </div>
      </div>
    </AdminBase>
  );
};

export default VolunteerList;
