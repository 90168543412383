import React from "react";
import "./PreLoader.css";
import { useEffect } from "react";
import { preLoaderAnim } from "../index";
const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      <div className="texts-container">
        <span>Your help matters,&nbsp;</span>
        <span>no matter how big &nbsp;</span>
        <span>or small.</span>
      </div>
    </div>
  );
};

export default PreLoader;
