import React from "react";
import classes from "./MerchantListCard.module.css";
import img from "../../assests/images/Shirts/Shirt1.jpg";
import companylogo from "../../assests/images/company.jpg";

const MerchantListCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.username}>
        <img src={companylogo} alt="" className={classes.img} />
      </div>
      <img src="{img}" alt="" className={classes.image} />
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> USER ID :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b>ROLE :</b> <span>Anurag kumar gupta</span>{" "}
        </div>
        <div className={classes.div}>
          <b>EMAIL :</b> <span>Anurag@gmail.com</span>
        </div>
        <div className={classes.div}>
          <b> MOBILE NUMBER:</b> <span>+91 9696058889</span>
        </div>
        <div className={classes.div}>
          <b> GST :</b> <span>9876543210987654</span>
        </div>
        <div className={classes.div}>
          <b>AADHAAR :</b> <span>9876 5432 1987</span>{" "}
        </div>
        <div className={classes.div}>
          <b>FIRM :</b> <span>DKI</span>
        </div>
        <div className={classes.div}>
          <b>OWNER NAME:</b> <span>DKI</span>
        </div>
        <div className={classes["address-block"]}>
          <img src={img} className={classes["company-proof"]} alt="" />
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>ADDRESS: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
              aliquam quibusdam iure sint quia tempore, adipisci corrupti id
              assumenda eum cumque quis. Voluptatibus veniam non, sapiente rerum
              ab laudantium nisi.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.btn} disabled>
          DEACTIVATE
        </button>
        <button className={classes.btn}>GIFT POINT HISTORY</button>
      </div>
    </div>
  );
};

export default MerchantListCard;
