import React from "react";
import classes from "./SizeCard.module.css";
import trash from "../../assests/images/trash.png";
const SizeCard = ({ size, color }) => {
  return (
    <div className={classes.card} style={{ backgroundColor: `${color}` }}>
      <h4 className={classes["card-title"]}>{size.toUpperCase()}</h4>
      <h6>Size</h6>
      <hr></hr>
      <img src={trash} alt="" className={classes.trash} />
    </div>
  );
};

export default SizeCard;
