import styled from "styled-components";

export const Heading = styled.div`
  padding: 10px;
  margin-top: 50px;
  text-align: center;

  background-color: teal;
  h1 {
    font-size: 1.875;
    letter-spacing: 5px;
    font-weight: 600;
    line-height: 0.4rem;

    color: white;
    margin-top: 20px;
  }
`;
