//function to check if the session storage contains token or any data
export const isLoggedIn = () => {
  let data = sessionStorage.getItem("data");
  if (data === null) {
    return false;
  } else {
    return true;
  }
};

//function to Login with session data
export const doLogin = (data) => {
  sessionStorage.setItem("data", JSON.stringify(data));
};

//function to logout and clears session data
export const doLogout = () => {
  sessionStorage.removeItem("data");
};

//get token of login user
export const getCurrentUserToken = () => {
  if (isLoggedIn()) {
    let data = JSON.parse(sessionStorage.getItem("data"));
    let token = data.token;
    return token;
  }
};

//function to get Current UserName
export const getCurrentUserName = () => {
  if (isLoggedIn()) {
    let data = JSON.parse(sessionStorage.getItem("data"));
    let username = data.user.fullName;
    return username;
  }
};

//function to get role of User
export const getCurrentUserRole = () => {
  if (isLoggedIn()) {
    let data = JSON.parse(sessionStorage.getItem("data"));
    let userRole = data.user.role;
    return userRole;
  }
};
