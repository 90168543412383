import React, { useState } from "react";
import logo from "../../assests/images/ShoffersLogo.png";
import { app_name } from "../../utils/HelperText";
import {
  Center,
  Container,
  Input,
  Language,
  Left,
  Logo,
  LogoImage,
  MenuItem,
  Right,
  SearchContainer,
  Wrapper,
  DropdownMenuContainer,
  DropdownMenu,
  DropdownMenuItem,
  HR,
} from "../../styledcomponents/home/CustomNavbar";
import { HorizontalSplit, Person2Rounded, Search } from "@mui/icons-material";
import { Badge } from "@mui/material";
import {
  doLogout,
  getCurrentUserName,
  isLoggedIn,
} from "../../network/SessionStorage";
import { successMsg } from "../../utils/Messages";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
const CustomNavbar = (props) => {
  const [currentUser, setCurrentUser] = useState("");
  useEffect(() => {
    if (isLoggedIn()) {
      setCurrentUser(getCurrentUserName());
    }
  }, []);

  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
    props.Toggle(toggle);
  };

  const Navigate = useNavigate();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const menuRef = useRef();
  const imgRef = useRef();

  return (
    <Container>
      <Wrapper>
        <Left>
          <LogoImage src={logo} />
          <Logo>{app_name}</Logo>
          <HorizontalSplit
            style={{ fontSize: 40, margin: "4px 0 0 80px", cursor: "pointer" }}
            onClick={() => handleToggle()}
          />
        </Left>
        <Center>
          <SearchContainer>
            <Input size={60}></Input>
            <Search style={{ color: "gray", fontSize: "16px" }} />
          </SearchContainer>
        </Center>
        <Right>
          <MenuItem>
            <Language>EN</Language>
          </MenuItem>

          {isLoggedIn() && (
            <MenuItem>
              <span style={{ color: "#624da9", fontWeight: 900 }}>
                WELCOME &nbsp;
              </span>{" "}
              {currentUser}
            </MenuItem>
          )}
          <MenuItem>
            <Badge badgeContent={4} color="primary">
              <Person2Rounded
                ref={imgRef}
                onClick={() => setToggleDropdown(!toggleDropdown)}
              />
            </Badge>
            {toggleDropdown && (
              <DropdownMenuContainer ref={menuRef}>
                <DropdownMenu>
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <HR></HR>
                  <DropdownMenuItem
                    onClick={() => {
                      doLogout();
                      Navigate("/");
                      setTimeout(() => {
                        successMsg("Logout Successful");
                      }, 1000);
                    }}
                  >
                    Logout
                  </DropdownMenuItem>
                  <HR></HR>
                </DropdownMenu>
              </DropdownMenuContainer>
            )}
          </MenuItem>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default CustomNavbar;
