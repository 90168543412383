import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  background-color: white;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 50px 0 0 50px;
  height: 400px;
  width: 900px;
  box-shadow: 5px 5px 5px 5px #888888;
  flex-direction: column;
  background-color: white;
  text-align: center;
  justify-content: center;
`;

export const Menu = styled.div`
  width: 100%;
`;

export const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background: #8621f8;
    color: black;
    transition: all 0.3s ease;
  }
`;
export const MenuLink = styled.a`
  flex: 10;
  color: fff;

  font-size: 20px;
  text-decoration: none;
  display: block;
  // padding: 5px 30px;
  line-height: 60px;
  color: white;
`;

export const SubMenu = styled.div`
  right: 0;
  transition: all 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubItem = styled.div`
  text-decoration: none;
  color: white;
  height: 40px;
  align-item: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  cursor: pointer;
  padding: 10px;
`;
export const SubItemLink = styled.a`
  text-decoration: none;
  padding-left: 80px;
  color: white;
  @hover;
`;
