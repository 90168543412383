import React from "react";
import {
  Container,
  Filter,
  FilterContainer,
  Title,
  FilterText,
  Select,
  Option,
} from "../../styledcomponents/productlist/ProductList";
import AllProducts from "../../components/AllProducts";
import Base from "./Base";

const ProductList = () => {
  return (
    <Container>
      <Base>
        <Title>Dresses</Title>
        <FilterContainer>
          <Filter>
            <FilterText>Filter Products:</FilterText>
            <Select>
              <Option disabled selected>
                Color
              </Option>
              <Option>White</Option>
              <Option>Black</Option>
              <Option>Red</Option>
              <Option>Blue</Option>
              <Option>Yellow</Option>
              <Option>Green</Option>
            </Select>
            <Select>
              <Option disabled selected>
                Size
              </Option>
              <Option>XS</Option>
              <Option>S</Option>
              <Option>M</Option>
              <Option>L</Option>
              <Option>XL</Option>
              <Option>XXL</Option>
            </Select>
          </Filter>
          <Filter>
            <FilterText>Sort Products:</FilterText>
            <Select>
              <Option selected>Newest</Option>
              <Option>Price (Low to High)</Option>
              <Option>Price (High to Low)</Option>
            </Select>
          </Filter>
        </FilterContainer>
        <AllProducts />
      </Base>
    </Container>
  );
};

export default ProductList;
