import { Container } from "../styledcomponents/home/Products";
import { data } from "../data/ProductData";
import NewArrivalItems from "./NewArrivalItems";

const NewArrivals = () => {
  return (
    <Container>
      {data.map((output) => (
        <NewArrivalItems item={output} key={output.id} />
      ))}
    </Container>
  );
};

export default NewArrivals;
