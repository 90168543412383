import React from "react";
import AdminBase from "../AdminBase";
import classes from "../../../styledcomponents/admin/banner/BannerList.module.css";
import CustomSubHeading from "../../../components/CustomSubHeading";
import BannerListCard from "../../../ui/bannerListCard/BannerListCard";
import img from "../../../assests/images/Shirts/Shirt1.jpg";
const BannerList = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Banner list" />
      <div className={classes.banner}>
        <div className={classes.wrapper}>
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
          <BannerListCard
            title="banner1"
            desc="WIN RS.10000 FREE SHOPPING BY LUCKY COUPON"
            img={img}
          />
        </div>
      </div>
    </AdminBase>
  );
};

export default BannerList;
