import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/category/AddCategory.module.css";
import { Form } from "../../../styledcomponents/login/Login";
import { InputAdornment, TextField } from "@mui/material";
import { motion as m } from "framer-motion";
import {
  Description,
  Label,
  Money,
  ProductionQuantityLimits,
  Title,
} from "@mui/icons-material";
const AddPackage = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="Add Package" />
      <div className={classes.category}>
        <div className={classes["category-wrapper"]}>
          <Form>
            <select
              className={classes.select}
              name="add-package"
              id="add-package"
            >
              <option value="">---SELECT PACKAGE TYPE---</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
              <option value="">Option 1</option>
            </select>
            <TextField
              id="title"
              label="TITLE"
              variant="outlined"
              InputProps={{
                className: classes.catname,
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="price"
              label="PRICE"
              variant="outlined"
              InputProps={{
                className: classes.catname,

                startAdornment: (
                  <InputAdornment position="start">
                    <Money />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="coupons"
              label="NUMBER OF COUPON"
              variant="outlined"
              InputProps={{
                className: classes.catname,

                startAdornment: (
                  <InputAdornment position="start">
                    <Label />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="price"
              label="ALLOWED NUMBER OF PRODUCTS"
              variant="outlined"
              InputProps={{
                className: classes.catname,

                startAdornment: (
                  <InputAdornment position="start">
                    <ProductionQuantityLimits />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="price"
              label="DISCRIPTION"
              variant="outlined"
              multiline
              InputProps={{
                className: classes.catname,

                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />
          </Form>
        </div>
      </div>
    </AdminBase>
  );
};

export default AddPackage;
