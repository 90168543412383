import React, { useState } from "react";
import Footer from "../../components/Footer";
import CustomNavbar from "../../components/admin/CustomNavbar";
import CustomSidebar from "../../components/admin/CustomSidebar";
const AdminBase = ({ title = "", children }) => {
  const [toggleState, setToggleState] = useState(true);
  const ontoggle = (data) => {
    setToggleState(data);
  };

  return (
    <>
      <CustomNavbar Toggle={ontoggle} />
      <CustomSidebar toggle={toggleState} />
      {children}
      <Footer />
    </>
  );
};

export default AdminBase;
