import React from "react";
import classes from "../styledcomponents/home/NewArrivalItems.module.css";
import { useNavigate } from "react-router-dom";
const NewArrivalItems = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.container} onClick={() => navigate("/productlist")}>
      <img src={item.image} alt="" className={classes.img} />
      <div className={classes.price}>
        <h4>Starting From</h4>
        <h3>$1300</h3>
      </div>
    </div>
  );
};

export default NewArrivalItems;
