import React from "react";
import classes from "./../volunteerCard/VolunteerListCard.module.css";
const PackageHistoryCard = () => {
  return (
    <div className={classes.card}>
      <div className={classes.username}>
        <h6>free package</h6>
      </div>
      <div className={classes.userinfo}>
        <div className={classes.div}>
          <b> package code :</b> <span>112</span>
        </div>
        <div className={classes.div}>
          <b>order id :</b> <span>123456</span>{" "}
        </div>
        <div className={classes.div}>
          <b>Purchased by :</b> <span>5000</span>
        </div>
        <div className={classes.div}>
          <b>Total Coupans :</b> <span>5000</span>
        </div>
        <div className={classes.div}>
          <b> Total Products:</b> <span>+91 9696058889</span>
        </div>
        <div className={classes.div}>
          <b> buy date:</b> <span>+91 9696058889</span>
        </div>
        <div className={classes["address-block"]}>
          <div className={classes.address}>
            <b>description: </b>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Dignissimos sint perferendis, quisquam maiores sapiente rem quam
              dolor. Asperiores, explicabo sint? Ratione, voluptate? Mollitia
              eum deserunt ea velit corrupti. Libero, tempore.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div
          className={classes.btn}
          style={{ textAlign: "center", fontSize: 20 }}
        >
          <b> 1000 ₹</b>
        </div>
      </div>
    </div>
  );
};

export default PackageHistoryCard;
