import React from "react";
import {
  Agreement,
  Button,
  Company,
  CompanyName,
  Container,
  Form,
  Input,
  Logo,
  Title,
  Wrapper,
} from "../styledcomponents/register/Register";
import { Link } from "../styledcomponents/login/Login";
import { useNavigate } from "react-router-dom";
import logo from "../assests/images/ShoffersLogo.png";
import { app_name } from "../utils/HelperText";
import { useRef } from "react";
import { RegisterValidations } from "../validations/Auth";
import { postRequest } from "../network/Services";
import { signup } from "../network/APIUrls";
import { headerWithoutToken } from "../network/Headers";
import { StatusCheck } from "../network/ResponseStatus";
import { ServerDown } from "../utils/Messages";
import { useState } from "react";
import GlobalSpinners from "../utils/spinners/GlobalSpinners";

const Register = () => {
  const Navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);

  //references for input fields
  const fullNameRef = useRef();
  const phnNumberRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  //submit handler for form
  const submitFormHandler = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const fullName = fullNameRef.current.value;
    const phoneNumber = phnNumberRef.current.value;
    const password = passwordRef.current.value;
    const confirmpassword = confirmPasswordRef.current.value;
    const data = { fullName, phoneNumber, password, confirmpassword };
    if (RegisterValidations(data)) {
      postRequest(signup, data, headerWithoutToken())
        .then((response) => {
          if (StatusCheck(response)) {
            setTimeout(() => {
              setSpinner(false);
            }, 1000);
          } else {
            setSpinner(false);
          }
        })
        // in case there is a error from server side or server is down
        .catch((error) => {
          if (error.response === undefined) {
            ServerDown();
            setSpinner(false);
          } else {
            StatusCheck(error.response);
          }
        });
    }
    // if case validation is false
    else {
      setSpinner(false);
    }
  };
  return (
    <Container>
      {spinner && <GlobalSpinners />}
      <Company>
        <Logo src={logo} />
        <CompanyName onClick={() => Navigate("/")}>{app_name}</CompanyName>
      </Company>
      <Wrapper>
        <Title>CREATE AN ACCOUNT</Title>
        <Form>
          {/* <Input placeholder="name" type="text" autoComplete="off" />
          <Input placeholder="lastname" type="text" autoComplete="off" /> */}
          <Input
            ref={fullNameRef}
            placeholder="Full Name"
            type="text"
            autoComplete="off"
          />
          <Input
            ref={phnNumberRef}
            placeholder="Phone Number"
            type="Mobile Number"
            autoComplete="off"
          />
          <Input
            ref={passwordRef}
            placeholder="password"
            type="password"
            autoComplete="off"
          />
          <Input
            ref={confirmPasswordRef}
            placeholder="confirmpassword"
            type="password"
            autoComplete="off"
          />
          <Agreement>
            By creating an account, I consent to the proccessing of my personal
            data in accordance with the <b>PRIVACY POLICY</b>
          </Agreement>
          <Button onClick={submitFormHandler}>CREATE ACCOUNT</Button>
        </Form>
        <Link onClick={() => Navigate("/login")}>ALREADY HAVE ACCOUNT</Link>
      </Wrapper>
    </Container>
  );
};

export default Register;
