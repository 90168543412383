import React from "react";
import classes from "../../../styledcomponents/points&coupons/PointsNCoupons.module.css";
import DataTable from "react-data-table-component";
const LuckyDraw = () => {
  const columns = [
    {
      name: "WINNER NAME",
    },
    {
      name: "COUPON",
    },
    {
      name: "ADDRESS",
    },
    {
      name: "POSITION",
    },
    {
      name: "GIFT POINTS",
    },
    {
      name: "DRAW DATE",
    },
    {
      name: "LUCKY DRAW PERIOD",
    },
  ];
  return (
    <div className={classes["joining-container"]}>
      <div className={classes["joining-points"]}>
        <h3>Winners List</h3>
      </div>
      <div className={classes["joining-point-history"]}>
        <DataTable columns={columns} />
      </div>
    </div>
  );
};

export default LuckyDraw;
