import React from "react";
import classes from "../styledcomponents/home/SplitCustomHeading.module.css";
const SplitCustomHeading = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <h1>Active</h1>
      </div>
      <div className={classes.right}>
        <h1>Inactive</h1>
      </div>
    </div>
  );
};

export default SplitCustomHeading;
