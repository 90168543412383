import React from "react";
import classes from "./BannerListCard.module.css";

const BannerListCard = ({ title, desc, img }) => {
  return (
    <div className={classes.card}>
      <div className="card-body">
        <h5 className={classes.title}>{title.toUpperCase()}</h5>
        <img src={img} alt="" className={classes.img} />
        <p className="text-center">{desc}</p>
        <div className={classes.footer}>
          <button className={classes.btn}>Edit</button>
          <button className={classes.btn}>Delete</button>
        </div>
      </div>
    </div>
  );
};

export default BannerListCard;
