export const data1 =
  "This app is made to make the purchase of the customer pleasant, through this app the shopkeeper can offer Lucky coupon and discount points to his customer on the pre-determined amount. In this app, for the convenience of the customer, one can contact the shopkeeper himself and with the consent of both, can take delivery of the goods sitting at home, take home delivery charge or make it free, it will depend on the shopkeeper.";

export const data2 =
  "ये app ग्राहक की खरीदारी को खुशनुमा बनाने के लिये बनाई गयी है, इस app द्वारा दुकानदार अपने ग्राहक को पहले से तय amount पर Lucky coupon व Discount points offer दे सकता है,इस app में ग्राहक की सुविधा के लिये दुकानदार से स्वयं सम्पर्क कर सकता है और दोनों की सहमति से घर बैठे सामान की delivery ले सकता है home delivery चार्ज ले या फ्री करे ये दुकानदार पर निर्भर होगा,";

export const data3 =
  "In this, the registered shopkeeper can give a lucky coupon to the registered customer in the app on a pre-determined purchase, the date of the lucky draw of that lucky coupon will be decided by the admin in advance, along with the lucky coupon, the merchant will have to set the merchant points first.There are three types of points in this app Joining points, Merchant points, and Gift points.";

export const data4 =
  "इस आप में registerd दुकानदार पहले से तय खरीद पर app में registerd ग्राहक को Lucky coupon दे सकता है उस Lucky coupon के Lucky draw की तारीख admin द्वारा पहले से तय होगी lucky coupon के साथ merchant द्वारा merchant points पहले set करने होंगे.इस app में तीन तरह के points हैं Joining points, Merchant points, and Gift points.";

export const data5 =
  "When a person registers this app, he will get 100 joining points (one point = Rs 1, every point will be valid for 90 days) If a register user registers someone else in the app with his referral code, then he will get 20 points, the more the user registers the other with his code, the more points he will earn. In lieu of these joining points, the customer will get a discount at Namaskar saree center 1 Alambagh lucknow, but to get the discount of 100 points, a minimum of 1000 rupees will have to be purchased. (The condition of minimum shopping can be changed from time to time). If there are registered merchants in this app, they can give a discount in exchange for joining points, this will increase their sales.";

export const data6 =
  "ज़ब कोई व्यक्ति इस app को register करेगा उसे 100 joining points मिलेंगे (एक point =1 रु, हर points 90 दिन तक valid होगा )कोई register user किसी दूसरे को अपने referral code से app में register करवाता है तो उसे 20 points मिलेंगे user जितना ज़्यादा दूसरे को अपने code से register करवाएगा उतना ज़्यादा points earn करेगा. इन joining points के बदले में customer को Namaskar saree centre 1 Alambagh lucknow में discount मिलेगी लेकिन 100 points की discount लेने के लिये minimumm 1000 रु की खरीदारी करनी होगी.( minimum shopping की शर्त को समय समय पर परिवर्तन किया जा सकता है ).इस app में जितने registerd merchants हैं चाहें तो joining points के बदले discount दे सकते हैं इससे उनकी sale बढ़ेगी.";

export const data7 =
  "During the lucky draw period, all the merchants registered in this app will give a lucky coupon to their customers by registering them in the app, all those coupons will be drawn on the date fixed by the admin (in this the amount of prizes and the number of prizes will be given by the admin in advance. will be decided.) Gift points equal to the amount of prize in the Gift points column will be sent by the admin to the number of customers who are the winners in the Lucky draw. (1point = Rs.1) The duration of each point will be 90 days. In lieu of gift points, the customer will do free shopping from any merchants registered in the app, if any other shopkeeper refuses to give free shopping, then you can definitely do it from Namaskar saree center 1 Alambagh lucknow In exchange for any merchant gift points, the amount of goods given to the customer for free will be given to that merchant by the admin in cash transfer in his bank account.";

export const data8 =
  "ग्राहक को लकी ड्रा अवधी के दौरान इस app में registerd सभी merchant अपने ग्राहकों को app में register करवाकर lucky coupon देंगे उन उन सभी कूपन का admin द्वारा तय तारीख पर lucky draw निकाला जायेगा (इसमें इनामो की राशि और इनामों की संख्या एडमिन द्वारा पहले से तय होगी.) Lucky draw में जितने ग्राहक विजेता होंगे उनके Gift points कॉलम में इनाम की राशि के बराबर gift points एडमिन द्वारा send कर दिये जायेंगे. ( 1point =1 रु होगी ) हर points की अवधी 90 दिन होगी. Gift points के बदले ग्राहक app में registerd किसी भी merchants से फ्री shopping करेगा अगर कोई अन्य दुकानदार फ्री shopping देने से मनाः करता है तो Namaskar saree centre 1 Alambagh lucknow से ज़रूर कर सकते हैं कोई भी merchant gift points के बदले जितना सामान ग्राहक को फ्री देगा उतना अमाउंट उस merchant को एडमिन द्वारा उसके bank account में cash transfer कर दिये जायेगी.";

export const data9 =
  "If the customer goes to the shop and buys goods from a shop, then the customer himself has to understand the terms of return and replacement of the goods and if shopping online, he will have to give direct information to that merchant within 1 (one hour) from the time of receiving the goods so that the shopkeeper can buy his goods. Get it back and the money will transfer in the customer's bank account within 15 days of getting the goods back.";

export const data10 =
  "अगर ग्राहक दुकान पर जाकर किसी दुकान से सामान खरीदेगा तो ग्राहक को सामान वापसी व बदलने की शर्ते स्वयं समझनी होगी और अगर ऑनलाइन shopping करेंगे तो सामान मिलने के समय से 1( एक घंटे के अंदर उस merchant को डायरेक्ट सूचना देनी होगी ताकि दुकानदार अपना सामान वापस मंगवा ले और सामान वापस मंगवाने के 15 दिन के अंदर ग्राहक के bank account में पैसा transfer करेगा.";

export const data11 =
  "After submitting the requested information and id address proof by clicking on Become a merchant, when you will get approval from the admin, then the merchant can buy the package according to his convenience, upload his product and give lucky coupons to the customers.";

export const data12 =
  "Become a merchant पर click करके मांगी गयी जानकारी और id address proof submit करने के बाद ज़ब आपको एडमिन द्वारा approval मिल जायेगा तब merchant अपनी सुविधा अनुसार package buy करके अपने product upload कर सकता है और customers को लकी कूपन दे सकता है";

export const data13 =
  "- If the customer wants to give merchant points with lucky coupons, then before starting to give coupons, setting up of merchant points will have to be done.- The number of merchant points you have given to your customer, you will definitely give a discount of Rs. - If the customer comes to you for free shopping in exchange for gift points, then after verification from the admin instead of those points, you can give as many goods as you will give free to the customer in exchange for gift points, cash transfer in your bank account within 7 days. Will be done. -Whatever the merchant will upload his product, its quality should be good and the price should be reasonable. - If the customer or admin is not satisfied with the quality or service of the merchant's product, the merchant will be deactivated. - The package purchased by the merchant will expire after the lucky draw is exhausted. Whatever the merchant will upload the images of his product, there should not be obscenity in it and not more than 100. Note: - Any dispute will be filed in Lucknow court.";

export const data14 = `
- ग्राहक को अगर लकी कूपन के साथ merchant points देने हैं तो कूपन देने की शरुआत करने से पहले merchant points की setting करनी होगी.- जितने merchant points आप द्वारा आपके ग्राहक को दिये होंगे उतने रु की discount आप अगले बिल पर अवश्य देंगे.
- जितने merchant points आप द्वारा आपके ग्राहक को दिये होंगे उतने रु की discount आप अगले बिल पर अवश्य देंगे.
- ग्राहक gift points के बदले आपसे फ्री shopping करने आता है तो आप उस points के बदले एडमिन से वेरिफिकेशन करने के बाद दे सकते हैं जितने gift points के बदले सामान आप ग्राहक को फ्री देंगे उतने रु 7 दिन के अंदर आपके bank account में cash transfer की जायेगी.
- merchant अपने product जो भी अपलोड करेगा उसकी क्वालिटी अच्छी और दाम उचित होना चाहिये
- merchant के product की quality या सेवा से अगर ग्राहक या एडमिन संतुष्ट न होने पर merchant को deactivate कर दिया जायेगा.
- merchant द्वारा खरीदा गये package की अवधी Lucky draw निकलने के बाद expire हो जायेगी
- merchant अपने product की images जो भी upload करेगा उसमें अश्लीलता नहीं होनी चाहिये और 100 कब से ज़्यादा न हो.
नोट :- कोई भी विवाद लखनऊ न्यायालय में दाखिल किया जायेगा
`;
