import styled from "styled-components";
import register from "../../assests/images/register.webp";
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${register}) center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  flex-direction: column;
`;
export const Logo = styled.img`
  width: 100px;
  height: 100px;
`;
export const Company = styled.div`
  text-align: center;
`;
export const CompanyName = styled.h1`
  font-weight: 900;
  font-size: 44px;
  color: #000d1a;
  cursor: pointer;
`;
export const Wrapper = styled.div`
  padding: 20px;
  width: 40%;
  background-color: white;
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;
export const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;
export const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;
export const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;
