import {
  HorizontalSplit,
  Person2Rounded,
  Search,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import React, { useState } from "react";
import { app_name } from "../utils/HelperText";
import logo from "../assests/images/ShoffersLogo.png";
import {
  Container,
  Wrapper,
  Left,
  Language,
  SearchContainer,
  Center,
  Right,
  Input,
  Logo,
  MenuItem,
  LogoImage,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContainer,
  HR,
} from "../styledcomponents/home/CustomNavbar";
import { Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import {
  doLogout,
  getCurrentUserName,
  isLoggedIn,
} from "../network/SessionStorage";
import { successMsg } from "../utils/Messages";
import { useEffect } from "react";
const CustomNavbar = (props) => {
  const [currentUser, setCurrentUser] = useState();
  //get current username and this helps to render getcurrent function only once
  useEffect(() => {
    if (isLoggedIn()) {
      setCurrentUser(getCurrentUserName());
    }
  }, []);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
    props.Toggle(toggle);
  };
  const Navigate = useNavigate();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const menuRef = useRef();
  const imgRef = useRef();

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setToggleDropdown(false);
    }
  });
  return (
    <Container>
      <Wrapper>
        <Left>
          <LogoImage src={logo} />
          <Logo>{app_name}</Logo>
          <HorizontalSplit
            style={{ fontSize: 40, margin: "4px 0 0 80px", cursor: "pointer" }}
            onClick={() => handleToggle()}
          />
        </Left>
        <Center>
          <SearchContainer>
            <Input size={60} />
            <Search style={{ color: "gray", fontSize: "16px" }} />
          </SearchContainer>
        </Center>
        <Right>
          <MenuItem>
            <Language>EN</Language>
          </MenuItem>
          {!isLoggedIn() && (
            <>
              <MenuItem onClick={() => Navigate("/register")}>
                Register
              </MenuItem>
              <MenuItem onClick={() => Navigate("/mlogin")}>SignIn</MenuItem>
            </>
          )}
          {isLoggedIn() && (
            <>
              <MenuItem>
                <span style={{ color: "#624da9", fontWeight: 900 }}>HELLO</span>{" "}
                &nbsp;
                {currentUser}
              </MenuItem>

              <MenuItem onClick={() => Navigate("/cart")}>
                <Badge badgeContent={4} color="primary">
                  <ShoppingCartOutlined />
                </Badge>
              </MenuItem>
            </>
          )}
          {isLoggedIn() && (
            <MenuItem>
              <Person2Rounded
                ref={imgRef}
                onClick={() => setToggleDropdown(!toggleDropdown)}
              />

              {toggleDropdown && (
                <DropdownMenuContainer ref={menuRef}>
                  <DropdownMenu>
                    <DropdownMenuItem>Profile</DropdownMenuItem>
                    <HR></HR>
                    <DropdownMenuItem>WishList</DropdownMenuItem>
                    <HR></HR>
                    <DropdownMenuItem>Points</DropdownMenuItem>
                    <HR></HR>
                    <DropdownMenuItem>Rewards</DropdownMenuItem>
                    <HR></HR>
                    <DropdownMenuItem
                      onClick={() => {
                        doLogout();
                        Navigate("/");
                        setTimeout(() => {
                          successMsg("Logout Successful");
                        }, 1000);
                      }}
                    >
                      Logout
                    </DropdownMenuItem>
                    <HR></HR>
                  </DropdownMenu>
                </DropdownMenuContainer>
              )}
            </MenuItem>
          )}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default CustomNavbar;
