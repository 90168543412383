import React from "react";
import classes from "../../styledcomponents/admin/About.module.css";
const Card = ({ title, image, desc }) => {
  return (
    <div className={classes.card}>
      <h5 className={classes["card-title"]}>{title}</h5>

      <div className={classes["card-body"]}>
        <img className={classes["card-images"]} src={image}></img>
        <div className={classes["card-text"]}>{desc} </div>
      </div>
    </div>
  );
};

export default Card;
