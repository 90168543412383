import React from "react";
import classes from "../../styledcomponents/admin/CustomSidebar.module.css";
import {
  AccountBox,
  ArrowForwardIos,
  Brightness1Sharp,
  Casino,
  Category,
  Group,
  HomeSharp,
  Inventory2,
  Tour,
  WidgetsSharp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
const CustomSidebar = (props) => {
  return (
    <>
      <aside
        id="sidebar"
        className={
          props.toggle ? classes["toggle-sidebar"] : classes["sidebar"]
        }
      >
        <ul className={classes["sidebar-nav"]} id="sidebar-nav">
          <li className={classes["nav-item"]}>
            <Link className={classes["nav-link"]} to="/admin">
              <HomeSharp className={classes.icons} />
              <span>HOME</span>
            </Link>
          </li>

          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <WidgetsSharp className={classes.icons} />
              <span>PRODUCTS</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            {/* Products Module start  */}
            <ul
              id="components-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/products/products">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Products</span>
                </Link>
              </li>

              <li>
                <Link to="/admin/products/sizelist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Size List</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* Products Module end  */}

          {/* Category module start */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#forms-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Category className={classes.icons} />
              <span>CATEGORY</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="forms-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/category/addcategory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Add Category</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/category/categorylist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Category List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/category/subcategory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Add Sub Category</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/category/subcategorylist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Sub Category List</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* end of category module */}

          {/* Start of Banner module */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Tour className={classes.icons} />
              <span>BANNER</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="tables-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/banner/addbanner">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Add Banner</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/banner/bannerlist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Banner List</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* End of Banner module */}
          {/* Start of Users module */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#charts-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Group className={classes.icons} />
              <span>USERS</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="charts-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/users/userlist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>All Users List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/rolelist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>User Role List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/merchantlist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Merchant Role List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/volunteerlist">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Volunteer Role List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/deactivatedusers">
                  <Brightness1Sharp className={classes.circle} />
                  <span>All Deactivated User List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/merchantrequest">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Merchant Request List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/volunteerrequest">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Volunteer Request List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/users/donate">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Request to Donate Old Items</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* End of Users module */}
          {/* Start of Package Module */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#icons-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Inventory2 className={classes.icons} />
              <span>PACKAGES</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="icons-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/package/packageType">
                  <Brightness1Sharp className={classes.circle} />
                  <span>All Package List</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/package/addpackage">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Add Package</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/package/packagerequest">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Package Request</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/package/packagebuyhistory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Package Buy History</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/package/assigncoupontovol">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Assign Coupon to Volunteer</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/package/volunteercouponhistory">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Volunteer Coupon History</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* End of Package Module */}
          <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#profile-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <AccountBox className={classes.icons} />
              <span>PROFILE</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="profile-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="icons-bootstrap.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Other Details</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/admin/luckydraw">
              <Casino className={classes.icons} />
              <span>LUCKY DRAW</span>
            </Link>
          </li>
          {/* <li className={classes["nav-item"]}>
            <Link
              className={`${classes["nav-link"]} collapsed`}
              data-bs-target="#lucky-draw-nav"
              data-bs-toggle="collapse"
              to="#"
            >
              <Casino className={classes.icons} />
              <span>LUCKY DRAW</span>
              <ArrowForwardIos className={classes["bi-chevron-down"]} />
            </Link>
            <ul
              id="lucky-draw-nav"
              className={`${classes["nav-content"]} collapse`}
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/admin/luckydraw">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Add Settings</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Settings List</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Lucky Draw Dates</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Winners Not Given Points</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>All Winners List</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Data Wise Winners List</span>
                </Link>
              </li>
              <li>
                <Link to="icons-remix.html">
                  <Brightness1Sharp className={classes.circle} />
                  <span>Gift Point Request List</span>
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/admin/about">
              <Info className={classes.icons} />
              <span>ABOUT</span>
            </Link>
          </li>
          <li className={classes["nav-item"]}>
            <Link className={`${classes["nav-link"]}`} to="/admin/contact">
              <ContactSupport className={classes.icons} />
              <span>CONTACT</span>
            </Link>
          </li> */}
        </ul>
      </aside>
    </>
  );
};

export default CustomSidebar;
