import React from "react";
import AdminBase from "../AdminBase";
import classes from "../../../styledcomponents/admin/category/CategoryList.module.css";
import img from "../../../assests/images/Shirts/Shirt1.jpg";
import CustomHeading from "../../../components/CustomHeading";
import CatergoryListCard from "../../../ui/categoryList/CatergoryListCard";
const CategoryList = () => {
  return (
    <AdminBase>
      <CustomHeading title="CATERGORY LIST" />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <CatergoryListCard img={img} title="MEN" />
          <CatergoryListCard img={img} title="WOMEN" />
          <CatergoryListCard img={img} title="CHILDREN" />
        </div>
      </div>
    </AdminBase>
  );
};

export default CategoryList;
