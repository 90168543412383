import React from "react";
import Slider from "../../components/Slider";
import AdminBase from "./AdminBase";

const AdminHome = () => {
  return (
    <AdminBase>
      <Slider />
    </AdminBase>
  );
};

export default AdminHome;
