import React from "react";
import AdminBase from "../AdminBase";
import CustomSubHeading from "../../../components/CustomSubHeading";
import classes from "../../../styledcomponents/admin/banner/AddBanner.module.css";
import { Form } from "../../../styledcomponents/login/Login";
import { InputAdornment, TextField } from "@mui/material";
import { Description, InsertDriveFile, Title } from "@mui/icons-material";

const AddBanner = () => {
  return (
    <AdminBase>
      <CustomSubHeading title="ADD Banner" />
      <div className={classes.banner}>
        <div className={classes["banner-wrapper"]}>
          <Form>
            <TextField
              id="banner-name"
              label="BANNER TITLE"
              variant="outlined"
              InputProps={{
                className: classes.name,
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="banner-name"
              label="BANNER DESCRIPTION"
              variant="outlined"
              InputProps={{
                className: classes.name,
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="banner-icon"
              label="UPLOAD BANNER IMAGE"
              variant="outlined"
              type="file"
              InputProps={{
                className: classes.name,

                startAdornment: (
                  <InputAdornment position="start">
                    <InsertDriveFile />
                  </InputAdornment>
                ),
              }}
            />
          </Form>
          <button className={classes.btn}>ADD</button>
        </div>
      </div>
    </AdminBase>
  );
};

export default AddBanner;
